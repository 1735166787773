<template>
  <div id="top">
    <!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
      <div class="hero-slider">
        <!-- <div class="hero-inner overlay" style="background-image: url(../assets/images/banner01.jpg);"> -->
        <div
          class="breadcrumbs overlay"
          :style="{
            backgroundImage:
              'url(' + require('../assets/images/banner01.jpg') + ')',
          }"
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">
                    {{ $t("banners.guest.h1Content") }}
                  </h1>
                  <p>{{ $t("banners.guest.content") }}</p>
                </div>
                <ul class="breadcrumb-nav">
                  <li>
                    <a style="cursor: pointer" @click="goToIndex()">{{
                      $t("banners.guest.button[0]")
                    }}</a>
                  </li>
                  <li>{{ $t("banners.guest.button[1]") }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="teachers" class="teachers section" style="padding-top: 20px;">
      <span class="horizontal-line" style="text-align: center;background-color: #f4f7fa;">{{ $t('speakerPage.speakerYears[0]') }}</span>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12" v-for="(item, index) in $t('speakerPage.year24')" :key="index">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img
                      :src= item.picUrl
                      alt="#"
                      height="320"
                    />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">{{item.title}}</span>
                      <h4 class="name">
                        <a href="teacher-details.html">{{item.name}}</a>
                      </h4>
                      <p style="text-indent: 2em">
                        {{item.designation}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img
                      src="../assets/images/guoli.png"
                      alt="#"
                      height="320"
                    />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2024年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">郭力</a>
                      </h4>
                      <p style="text-indent: 2em">
                        天津大学电气自动化与信息工程学院教授、博士生导师。天津大学国家储能创新平台储能应用中心副主任，
                        微网与智能配电系统开发与应用国家地方联合工程研究中心副主任，中国电机工程学会新能源并网专委会委员。
                        长期围绕新能源发电、锂离子电池储能系统以及新型电力系统的快速稳定控制和优化运行开展研究工作。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img
                      src="../assets/images/metting_user2.png"
                      alt="#"
                      height="320"
                    />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2024年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">王正伟</a>
                      </h4>
                      <p style="text-indent: 2em">
                        清华大学流体机械及工程研究团队负责人，长聘教授，博士生导师。IAHR
                        水力机械与系统方向学术委员会委员及亚洲分会主席，中国动力工程学会水轮机专业委员会副主任委员，全国水轮机标准化技术委员会委员，中国可再生能源学会理事，中国可再生能源学会海洋能专业委员会副主任委员。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img
                      src="../assets/images/liujihong.png"
                      alt="#"
                      height="320"
                    />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2024年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">刘继红</a>
                      </h4>
                      <p style="text-indent: 2em">
                        北京航天航空大学教授，博士生导师。曾先后获得华中科技大学、北京理工大学、东京都立大学学士硕士博士学位。主要研究方向及特色是现代设计理论与方法、数字化设计与制造技术、飞机制造技术、人工智能及其工程应用。中国计算机学会计算机辅助设计与图形学专业委员会常委、国家科技部“十二五”制造业信息化工程总体专家组成员。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img
                      src="../assets/images/liyanhao.png"
                      alt="#"
                      height="320"
                    />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2024年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">李彦浩</a>
                      </h4>
                      <p style="text-indent: 2em">
                        中国长江三峡集团上海勘测设计研究院有限公司高级工程师。中国电力技术市场协会优秀青年科技人才。扎根国内外重大水电工程勘测设计和科研一线，主持参与了乌干达伊辛巴、尼泊尔KGG、尼泊尔塔马柯西三、西藏紫霞水电站以及重庆菜籽坝、巫山大溪、海南羊林等抽水蓄能电站的水力机械科技攻关，发表高水平论文7篇，参编行业标准2项，获得国家、国际行业协会、集团公司等科技奖项6项。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img
                      src="../assets/images/zhouzhijun.png"
                      alt="#"
                      height="320"
                    />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2024年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">周志军</a>
                      </h4>
                      <p style="text-indent: 2em">
                        长江电力股份有限公司科学技术研究中心主任研究员、高级工程师。曾在长江三峡能事达股份有限公司担任调速事业部总经理、公司副总经理。长期从事水轮机控制及液压控制技术研发工作，主持参与了国家、省部级多项重大项目研发工作，其中主持公关完成国产700MW级水轮机调速器设备。获得国家科技进步二等奖1项，省部级科技奖2项，集团级科技奖2项。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img
                      src="../assets/images/zhanghaiku.png"
                      alt="#"
                      height="320"
                    />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2024年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">张海库</a>
                      </h4>
                      <p style="text-indent: 2em">
                        大唐水电科学技术研究院有限公司正高级工程师，工学博士。中国大唐集团首届青年拔尖人才，全国水轮机标准化委员会委员、中国水力发电学会理事、中国电工学会高级会员、西藏自治区水力发电工程学会金结机电专业委员会委员、能源动力重大装备及技术四川省协同创新中心委员，《大电机技术》中国科技核心期刊青年编辑委员会委员、《水电与新能源》编委会委员。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img
                      src="../assets/images/litian.png"
                      alt="#"
                      height="320"
                    />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2024年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">李天</a>
                      </h4>
                      <p style="text-indent: 2em">
                        天津排放权交易所董事，总经理，复旦大学MBA。曾在银行、保险、互联网企业工作多年。在蚂蚁集团工作期间，作为生活缴费行业总经理，带领团队打造了中国最大的线上缴费平台。2019年12月，出任天津排放权交易所有限公司总经理。天津排放权交易所是全国首批综合性环境权益交易机构，推动市场体系建设，已逐步成为服务政、产、学、研及市场相关方推进绿色低碳高质量发展的重要市场平台。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div style="margin-top: 20px">
            <span class="horizontal-line" style="text-align: center"
              >{{ $t('speakerPage.speakerYears[1]') }}</span
            >
          </div>

           <div class="col-lg-6 col-md-6 col-12" v-for="(item, index) in $t('speakerPage.year23')" :key="index">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img :src= item.picUrl alt="#" />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">{{item.title}}</span>
                      <h4 class="name">
                        <a href="teacher-details.html">{{item.name}}</a>
                      </h4>
                      <p style="text-indent: 2em">
                        {{item.designation}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img src="../assets/images/metting_user2.png" alt="#" />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2023年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">王正伟</a>
                      </h4>
                      <p style="text-indent: 2em">
                        清华大学流体机械及工程研究团队负责人，长聘教授，博士生导师。IAHR
                        水力机械与系统方向学术委员会委员及亚洲分会主席，中国动力工程学会水轮机专业委员会副主任委员，全国水轮机标准化技术委员会委员，中国可再生能源学会理事，中国可再生能源学会海洋能专业委员会副主任委员。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img src="../assets/images/metting_user03.png" alt="#" />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2023年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">刘新天</a>
                      </h4>
                      <p style="text-indent: 2em">
                        哈尔滨电机厂有限责任公司副总工程师、副总设计师/技术专家，正高级工程师。
                        主要研究方向:水轮发电机设计、初步设计、现场安装问题处理，调试问题处理等;曾主持并设计湖北水布垭发电机460MW,
                        曾荣获黑龙江省政府颁发科技技术进步奖二等奖;中国水力发电工程学会科技技术进步奖二等奖。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img src="../assets/images/metting_user04.png" alt="#" />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2023年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">郭伟</a>
                      </h4>
                      <p style="text-indent: 2em">
                        天津仁爱学院机械工程学院院长，天津大学机械工程学院长聘教授、机械工程及管理科学工程博士生导师，
                        博士，天津大学内燃机与工程热物理博士后流动站博士后，香港科技大学工业工程与工程管理系博士后。科技部制造业信息化科技工程总体专家组专家，
                        中国机械工程学会工业工程师资格认证专家组专家。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img src="../assets/images/metting_user05.png" alt="#" />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2023年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">李明涛</a>
                      </h4>
                      <p style="text-indent: 2em">
                        博士，西安交通大学新能源科学与工程系副教授，博士生导师。现任中国节能协会公共机构节约能源资源专业委员会委员、
                        中国化工学会过程模拟及仿真专业委员会青年委员、陕西省电力新能源及电动交通标准化委员会委员。开源组织Ai4Energy(https://github.com/ai4energy)创始人。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img src="../assets/images/metting_user06.png" alt="#" />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2023年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">孙江</a>
                      </h4>
                      <p style="text-indent: 2em">
                        大会主席、天津仁爱学院领军人才、教授、博士、天发总厂董事长、深圳恒发水电公司总经理。
                        天津首批“优秀企业家培养工程”等人才工程。天津仁爱学院领军人才。曾荣获天津市五一劳动奖章、中国机械工业优秀企业家等荣誉称号。
                        主持国家级、省部级科研创新项目12项，其中EI以上6篇，实用新型、发明专利近50项。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img src="../assets/images/metting_user07.png" alt="#" />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2023年主讲嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">霍乾涛</a>
                      </h4>
                      <p style="text-indent: 2em">
                        南瑞集团有限公司电控分公司副总经理，高级工程师。
                        长期从事发电机励磁、调相机控制、新能源主动支撑等方面的研究工作，
                        火电机组励磁系统等研制、设计及现场试验工作。提出了数字离散化控制模型的精确化方法，完成模型测试与入网检测等，
                        并通过CE认证。历获中国电机工程学会中国电力科学技术奖一等奖。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img src="../assets/images/metting_user08.png" alt="#" />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2023年标准宣贯嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">周林</a>
                      </h4>
                      <p style="text-indent: 2em">
                        澳大利亚RIZNER有限公司执行董事，中国水力发电工程学会继保与励磁专委会委员，澳大利亚工程师协会会员MIEAuSt。
                        周林长期从事电力系统保护控制及在线监测方面的研究工作，获电弧光保护相关专利技术7项;近期重点项目包括三峡集团的乌东德和白鹤滩巨型水电站的应用。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="single-team wow fadeInUp" data-wow-delay=".2s">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="image">
                    <img src="../assets/images/metting_user09.png" alt="#" />
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="info-head">
                    <div class="info-box">
                      <span class="designation">2023年标准宣贯嘉宾</span>
                      <h4 class="name">
                        <a href="teacher-details.html">罗志昭</a>
                      </h4>
                      <p style="text-indent: 2em">
                        广东日昭电工有限公司董事长，系节能铜包铝管母线第一发明人。
                        专业从事节能母线与绝缘配合的开发设计，研究与应用。发明了节能铜包铝管母线技术，获得国内外授权发明专利100多项。获得中国专利优秀奖2项等荣誉，
                        主持制定企业标准2项，地方标准2项，团体标准2项。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <a
      href="javascript:void(0)"
      class="scroll-top btn-hover"
      @click="scrollToAnchor('top')"
    >
      <i class="lni lni-chevron-up"></i>
    </a>
  </div>
</template>
  
<script>
import indexnav from "@/components/nav/indexnav.vue";
import banner from "@/components/header/banner.vue";

export default {
  name: "IndexPage",
  components: {
    indexnav,
    banner,
  },
  data() {
    return {
      bannerUrl: ["BANNER.png"],
    };
  },
  mounted() {
    //  TouchSlide({
    // 		slideCell:"#news_slide",
    // 		mainCell:".bd ul",
    // 		effect:"leftLoop",
    // 		autoPlay:true//自动播放
    //  });
    const slide = document.querySelector(".hero-slider");
    console.log(slide);
    tns({
      container: ".hero-slider",
      items: 1,
      slideBy: "page",
      autoplay: false,
      mouseDrag: true,
      gutter: 0,
      nav: true,
      controls: false,
      controlsText: [
        '<i class="lni lni-arrow-left"></i>',
        '<i class="lni lni-arrow-right"></i>',
      ],
    });
    document.addEventListener("click", (evnt) => {
      let anchor = evnt.target.closest('a[href^="#"]');
      if (anchor) {
        evnt.preventDefault();
        let value = anchor.getAttribute("set_data");
        if (value) {
          this.newsDetail(value);
        }
      }
    });
  },
  methods: {
    scrollToAnchor(id) {
      var anchor = document.getElementById(id);
      anchor.scrollIntoView();
    },
    goToIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    newsDetail(index) {
      console.log(11111);
      this.$router.push({
        path: "/newsDetail/" + index,
      });
    },
    caseDetail(type) {
      this.$router.push({
        path: "/caseDetail/" + type,
      });
    },
  },
};
</script>
  
<style>
.horizontal-line {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid;
}
</style>