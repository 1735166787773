<template>
    <div>
      <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle" :bannerTitle="bannerTitle" :subtitle="true"></banner>

    <div class="com_zi clearfix">
		<div class="left_zi">
			<div class="top_com">
				<span>工程案例</span>
			</div>
			<ul class="nav_left">
				<li :class="{'active':type == 1}">
					<a @click="checkType(1)">虾稻共作</a>
				</li>
				<li :class="{'active':type == 2}">
					<a @click="checkType(2)">高位池养殖</a>
				</li>
				<li :class="{'active':type == 3}">
					<a @click="checkType(3)">河蟹精养</a>
				</li>
			</ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a @click="checkType(1)">工程案例</a> > <a >{{ productName}}</a> </span>
			</div>
        <div  v-if="type == 1" class="pro_zi_show">
            <div class="pro_show1 clearfix">
					    <div >
						    <h4>南瑞稻虾</h4>
					    </div>
				    </div>
				    <div class="pro_show2">
					    <div class="top_3">案例详情</div>
						  <h5 style="font-weight: 700;margin: 5px 20px;">项目概况</h5>
						  <p style="padding: 1% 3%;text-indent: 2em;">
                国网电力科学研究院武汉南瑞项目地点位于湖北省洪湖市瞿家湾，养殖面积1000亩，池塘25个，池塘平均面积40亩，主养小龙虾。
                项目要求对养殖过程进行数据化追踪及按需用电，合理用电。避免电力浪费及过量投喂，提高养殖生产效率及降低养殖风险。
              </p>
              <h5 style="font-weight: 700;margin: 5px 20px;">实施内容</h5>
              <p style="padding: 1% 3%;">
                中易物联作为国家电网合作伙伴，根据项目需求完成了千亩稻虾的智慧渔业和智慧用能的项目实施，其主要内容如下：
                <br />
                1、水质在线监测系统：24小时实时监测养殖水体的溶解氧、温度、pH、水位等水质参数；系统具有水质指标超限告警功能。
                <br />
                2、设备智能控制及监测系统：对池塘增氧机、水泵等进行智能控制，运行状态实时监测；设备故障，停电实时告警。
                <br />
                3、大屏展示系统：现场安装展示大屏，实时展示养殖基地池塘水质、设备运行状态、视频监控画面及气象站相关数据。
                <br />
                4、视频监控系统：对池塘水面及关键出入口进行实时监控，可远程调取养殖基地监控画面，养殖过程可追溯，养殖行为可查询。
                <br />
                5、气象站建设：完成本地气象站建设，实时监测光照度、大气压、风向、风速、雨量、温度、湿度。
                <br />
                6、供电线路敷设：完成基地杆路规划、电杆及线路敷设。
              </p>
						  <!-- <img src="../../assets/images/case1_1.png"  style="width: 100%;" /> -->
				    </div>
        </div>
        <div v-if="type == 2" class="pro_zi_show">
				  <div class="pro_show1 clearfix">
					  <div >
						  <h4>鲟鱼高位池养殖</h4>
					  </div>
				  </div>
				  <div class="pro_show2">
					  <div class="top_3">案例详情</div>
              <h5 style="font-weight: 700;margin: 5px 20px;">项目概况</h5>
						  <p style="padding: 1% 3%;text-indent: 2em;">
                天全润兆鲟业有限公司鲟鱼基地坐落于天全冷水鱼现代农业园区核心区，拥有养殖水面46000平方米，通过“三三模式”建设“共享鱼池”，
                天全润兆鲟业有限公司自成立以来，先后在团结村建设冷水鱼养殖基地两期，占地面积233亩，其中一期110亩，二期123亩，共有流水鱼池266口。
                于2017年被农业农村部授予“农业农村部水产健康养殖示范场”称号。
              </p>
              <!-- <img src="../../assets/images/case2_1.png"  style="width: 100%;" /> -->
              <h5 style="font-weight: 700;margin: 5px 20px;">实施内容</h5>
              <p style="padding: 1% 3%;text-indent: 2em;">
                公司为四川雅安天全润兆鲟业冷水鱼养殖基地建立了一套完备的智慧水产管理体系，协助客户完成冷水鱼养殖基地的智慧化升级。为其提供了气象监测站、
                养殖水质监测设备15套，并提供了中易智慧渔业管理平台、养殖基地运营支撑系统、水产追溯系统等软件产品和相应服务，实现智慧水产项目一站式打造。
              </p>
              <p style="padding: 1% 3%;text-indent: 2em;">
                养殖水质监测设备可精确感知水体溶解氧、水温、PH值等参数，气象监测站可监测环境温湿度、降雨量、风速风向、光照度、紫外线强度等气象信息，
                两者相结合，可实现对于养殖基地环境的整体把握，这些数据会实时上传至智慧渔业云平台，用户可通过手机登录APP或电脑登录进行查看。
              </p>
						  <!-- <img src="../../assets/images/case2_2.png"  style="width: 100%;" /> -->
              <p style="padding: 1% 3%;text-indent: 2em;">
                智慧渔业系统可对安装于塘边的增氧机、投料机进行远程控制，用户可使用手机，启动增氧机、投料机对养殖池进行充氧、
                喂食、投药工作，全程无需工作人员亲自到场，一人操作手机，就能做完过去七个人的工作量。
              </p>
              <p style="padding: 1% 3%;text-indent: 2em;">
                公司为四川雅安天全润兆鲟业冷水鱼养殖基地完成的智慧化升级，有效提升了该基地的冷水鱼产量和质量，
                同时有效降低了该基地的人工成本，还通过水产追溯系统加强对养殖过程的管控，获得了广大消费者的信任，提升了产品的市场竞争力，获得了真正意义上的收益提升。
              </p>
              <p style="padding: 1% 3%;text-indent: 2em;">
                2022年12月，农业农村部公布2022年国家级水产健康养殖和生态养殖示范区名单，天全润兆鲟业有限公司成功入选。这是该市第一个水产养殖国家级健康养殖和生态
                养殖示范区，也是国内唯一集鲟鱼商品鱼生产、鱼子酱加工、运输和销售为一体的国家级生态养殖示范区。
              </p>
						  <!-- <img src="../../assets/images/case2_3.png"  style="width: 100%;" /> -->
						  <!-- <img src="../../assets/images/case2_4.png"  style="width: 100%;" /> -->
						  <!-- <img src="../../assets/images/case2_5.png"  style="width: 100%;" /> -->
				  </div>
			  </div>
        <div v-if="type == 3" class="pro_zi_show">
          <div class="pro_show1 clearfix">
					    <div >
						    <h4>淮安河蟹</h4>
					    </div>
				    </div>
				    <div class="pro_show2">
					    <div class="top_3">案例详情</div>
						  <h5 style="font-weight: 700;margin: 5px 20px;">项目概况</h5>
						  <p style="padding: 1% 3%;text-indent: 2em;">
                江苏洪泽湖项目位于江苏淮安，养殖面积500亩，主养大闸蟹，池塘平均面积30亩。本项目以渔业信息化项目建设为试点，践行水产标准化生态健康养殖发展规划，
                推进现代渔业信息化建设，调整渔业结构，改善生态环境，优化生产技术，有利于发挥本区域水资源优势，挖掘生产潜能，提高水产品的质量和市场竞争力，
                推进渔业产业化经营，促进可持续发展。
              </p>
              <h5 style="font-weight: 700;margin: 5px 20px;">实施内容</h5>
              <p style="padding: 1% 3%;text-indent: 2em;">
                根据项目实施养殖基地所在区域位置、养殖模式、养殖面积、品种、池塘大小、水深、放养密度，结合天气、水质信息、基础养殖设施等，
                给出从苗种投放、药品使用、饲料投喂、水质增氧、病害预防和控制等多方位做立体管理、智能化管理的日常行为管理解决方案。
                通过水产养殖基地渔业信息化试点项目建设，为实现公司智慧渔业系统运行，提高水产养殖效益潜力；实行产业经营，集约化、标准化生态健康养殖，
                实现产品生产无公害、绿色化取得实践经验。为推动传统水产业向现代水产业转变，增强竞争力，促进渔业高产高效；推动水产业健康、
                快速和可持续发展，提高渔业规模化、标准化、产业化程度，打下坚实的基础。
              </p>
              <p style="padding: 1% 3%;">
                项目实现的主要功能模块如下：
                <br/>
                1、水产养殖可视化平台建设
                <br/>
                2、养殖场环境监测系统建设
                <br/>
                3、养殖场视频监控系统建设
                <br/>
                4、水质在线监测系统建设
                <br/>
                5、水产智能控制系统建设
                <br/>
                6、水产养殖管理信息化系统建设
                <br/>
                7、水产品质量安全可追溯管理系统建设
              </p>
						  <!-- <img src="../../assets/images/case3_1.png"  style="width: 100%;" /> -->
				    </div>
			</div>
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"

import { h } from 'vue'
export default {
    name:"IndexPage",
  components:{
    
    Banner,
    MyNav,
   
    
  },

  data(){
    return {
      bannerUrl: ["201901161221277fdcad.jpg"],
      type: 1,
      productName:'虾稻共作',
      divStyle: '',
      imgStyle: '',
      bannerTitle: ["工程案例","CASE"]

    }
  },
  watch: {
    type(item1,item2){
      if(item1 != item2){
        if(this.type == 1){
          this.productName = '虾稻共作'
        }else if(this.type == 2){
          this.productName = '高位池养殖'
        }else if(this.type == 3){
          this.productName = '河蟹精养'
        }
      }
    }
  },
  created(){
    if(this.$route.params){
      this.type = this.$route.params.type;
      let width = window.innerWidth;
      if(width > 960){
        this.height = '500px';
      }else {
        this.height = 'none'
      }
       this.caseDetail(this.$route.params.type)
    }
  },
  mounted() {
     
  },
  methods: {
    checkType(type){
     this.$router.push({
          path:"/case/"+type
      })
    },
    caseDetail(type){
      if(type == 1){
        this.productName = '虾稻共作'
      }else if(type == 2){
        this.productName = '高位池养殖'
      }else if(type == 3){
        this.productName = '河蟹精养'
        
      }
    }
  }
}
</script>
  
<style>

</style>