<template>
  <div id="top">
	<!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
<div class="hero-slider">
    <div class="breadcrumbs overlay" :style="{backgroundImage: 'url(' + require('../assets/images/banner01.jpg') + ')' }">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-8 offset-lg-2 col-md-12 col-12">
<div class="breadcrumbs-content">
<h1 class="page-title">{{ $t("banners.registration.h1Content") }}</h1>
<!-- <p>2024第二届储能与智慧电站关键技术论坛以“智慧赋能 驱动未来”为主题，将于2024年10月18日至10月20日<br>在天津水游城丽筠酒店召开。</p> -->
<p>{{ $t("banners.registration.content") }}</p>
</div>
<ul class="breadcrumb-nav">
<li><a style="cursor:pointer" @click="goToIndex()">{{ $t("banners.registration.button[0]") }}</a></li>
<li>{{ $t("banners.registration.button[1]") }}</li>
</ul>
</div>
</div>
</div>
</div>
</div>  
</section>

<section class="section blog-single" v-if="this.$i18n.locale == 'zh'">
<div class="container">
<div class="row">
<div class="col-lg-10 offset-lg-1 col-md-12 col-12">
<div class="single-inner">

<div class="post-details">
<div class="detail-inner">

<ul class="custom-flex post-meta">
<li>
<a href="javascript:void(0)">
<i class="lni lni-calendar"></i>
2024年9月18日
</a>
</li>
</ul>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" >关于举办“2024第二届储能与智慧电站关键技术论坛”的会议通知 </a>
</h2>
<p>各有关单位：
<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为深入贯彻落实党的二十届三中全会和全国科技大会精神，贯彻落实习近平总书记关于科技创新的重要论述精神，为国家双碳战略实施贡献储能力量，提升储能装备技术创新和电站智能化运维水平，推动能源新质生产力发展，
2024第二届储能与智慧电站关键技术论坛以“智慧赋能 驱动未来”为主题，将于2024年10月18日至10月20日在天津水游城丽筠酒店召开。

<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;会议旨在为我国储能技术、智能电网、智慧电站等领域的专家、学者和产业界人士搭建一个专业、创新的学术交流平台。会议将邀请中国工程院院士天津大学王成山教授团队、清华大学、北京航空航天大学、中国大唐集团、中国长江三峡集团上海勘测设计研究院、长江电力股份有限公司等知名学者和业内一线资深专家围绕“储能装备、
水电装备、智慧电站、数字孪生、知识工程、智能设计、智能运维、增效扩容”等关键技术共同探讨，充分展示和分享创新理念和研究成果。
</p>
<h3>一、会议信息</h3>
<ul class="list">
<li><i class="lni lni-chevron-right-circle"></i>会议官网：https://ices.tj.cn/</li>
<li><i class="lni lni-chevron-right-circle"></i>大会时间：2024年10月18-10月20日</li>
<li><i class="lni lni-chevron-right-circle"></i>会议地点：天津水游城丽筠酒店</li>
</ul>
<p></p>

<h3>二、组织机构</h3>
<p><a class="sub_title">主办单位：</a>天津大学国家储能平台<br>
  <a class="sub_title"></a>天津仁爱学院<br>
  <a class="sub_title">承办单位：</a>天津天发总厂机电设备有限公司<br>
  <a class="sub_title"></a>天津天发总厂机电设备有限公司<br>
  <a class="sub_title">协办单位</a>中国水力发电工程学会继电保护与励磁专业委员会<br>
  <a class="sub_title"></a>天津机械工程学会<br>
  <a class="sub_title"></a>天津市电力工程协会<br>
  <a class="sub_title"></a>河北省水力发电工程学会<br>
  <a class="sub_title"></a>天津仁爱学院绿色水电研究院<br>
  <a class="sub_title">支持媒体：</a>《机械设计》杂志社<br>
</p>
<h3>三、专家委员会</h3>
<p>会议学术委员会：<br>
  王成山、张俊红、王莹、任成祖、郭伟、孙江、王正伟、刘新天、李明涛、陈小明<br>
  会议组委会：<br>
  1、会议主席：孙江<br>
  2、技术方案委员会：张俊红、李金伟、于洋洋<br>
  3、组织委员会：任成祖、郭伟、闫飞、贺莹、刘金剑、石丽雯、靳艳梅<br>
  4、出版主席：王莹<br>
</p>
<h3>四、专题报告</h3>
<p>
    1、王成山院士团队报告《储能运行控制问题与工程实践》<br>
    2、清华大学 王正伟 教授《水力机械及系统多场耦合》<br>
    3、北京航空航天大学 刘继红 教授《工业智能技术及其应用》<br>
    4、天津仁爱学院 孙江 教授《大型灯泡贯流机组性能检测和再制造关键技术与应用》<br>
    5、长江电力股份有限公司科学技术研究中心 主任研究员 周志军《水轮机控制系统数字孪生关键技术应用》<br>
    6、中国长江三峡集团上海勘测设计研究院有限公司高级工程师 李彦浩《新型电力系统下的抽水蓄能电站灵活运行发展前景和关键技术展望》<br>
    7、中国大唐集团“首届青年拔尖人才”正高级工程师 张海库《水电领域设备技术创新研究进展》<br>
    8、国家能源局华北监管局相关领导做行业政策解读报告<br>
    日程简表：<br>
    <table>
      <thead>
            <tr>
                <th colspan="2">日期</th>
                <th>内容</th>
            </tr>
        </thead>
        <tbody>
            <!-- 第1行 -->
            <tr>
                <td>10.18（周五）</td>
                <td>14:00-22:00</td>
                <td>大会报到</td>
            </tr>
            <!-- 第2行 -->
            <tr>
                <td rowspan="3">10.19（周六）</td>
                <td rowspan="2">9:00-12:00</td>
                <td style="border-bottom: 0;">开幕式</td>
            </tr>
            <!-- 第2行 -->
            <tr>
              <td style="border-top: 0;">大会报告</td>
            </tr>
            <tr>
              <td >14:00-17:30</td>
              <td >大会报告</td>
            </tr>
            <tr>
                <td rowspan="2">10.20（周日）</td>
                <td >10:00-12:00</td>
                <td >参 观</td>
            </tr>
            <tr>
               
                <td >下午</td>
                <td >离 会</td>
            </tr>
        </tbody>
    </table>
</p>
<h3>五、注册及会务费用</h3>
<p>1、录用文章收取每页1000元版面费，不再收取审稿费。注册会务费1500元/人，投稿参会人员可凭论文录用通知及版面费缴纳凭证抵扣1人会务费。注册后会议统一安排食宿，会议交通及食宿费用自理。<br>
  2、会务公司协办开发票，汇款可以通过银行对公转账或扫码方式进行，汇款信息如下：<br>
  <i class="lni lni-chevron-right-circle"></i><span style="margin-left: 5px;line-height: 30px;">公司名称：和丰（天津）管理咨询有限公司</span><br>
  <i class="lni lni-chevron-right-circle"></i><span style="margin-left: 5px;line-height: 30px;">开户行名称：兴业银行天津金纬路支行</span><br>
  <i class="lni lni-chevron-right-circle"></i><span style="margin-left: 5px;line-height: 30px;">银行账号：441180100100251776</span><br>
  <span style="margin-left: 30px;"><img src="../assets/images/pay.png" /></span><br>
  <span style="margin-left: 30px;color: #87ceeb;border-bottom: 1px solid;">注：扫码付款时请务必注明“单位简称+姓名”，并将付款凭证发至icestj@126.com。</span><br>
  3、报名联系方式<br>
  请参会代表于2024年10月14日（周一）前通过下方二维码进行报名，或将报名回执表（见附件1）发送至会务组邮箱icestj@126.com，会务组提前七天发送会议指南到各参会人员，详告报到路线等其他会务事项。<br>
  <i class="lni lni-chevron-right-circle"></i><a class="sub_title1">会务组电话：</a>025-83214633<br>
  <i class="lni lni-chevron-right-circle"></i><a class="sub_title1">会议联系人：</a>靳艳梅  电话：15900270919<br>
  <a class="sub_title1"></a>金焕成  电话：13820434300<br>
  <span style="margin-top: 10px;display: inline-block;"><img style="vertical-align: text-bottom;" src="../assets/images/tianbao.png" /><p style="vertical-align: text-bottom;text-align: center;font-weight: 700;"> 报名填报二维码  </p> </span>
  <span style="margin-top: 10px;display: inline-block;margin-left: 20px;"><img style="vertical-align: text-bottom;" src="../assets/images/wechatMany.png" /><p style="vertical-align: text-bottom;text-align: center;font-weight: 700;"> 参会人员微信大群  </p> </span>


 

</p>
<p>感谢您对本次大会的关注和支持，我们将对您的到来表示衷心的感谢与欢迎!</p>

<div class="post-tags-media">
<div class="post-tags popular-tag-widget mb-xl-40">
    <p class="share-title"><a  style="color: red;border-bottom: 1px solid;cursor: pointer;" @click="downLoadFile('会议通知 2024第二届储能与智慧电站关键技术论坛.pdf')">附件： 会议通知 2024第二届储能与智慧电站关键技术论坛.pdf</a></p>
    <p class="share-title"><a  style="color: red;border-bottom: 1px solid;cursor: pointer;" @click="downLoadFile('附件-报名回执表.docx')">附件： 报名回执表.docx</a></p>

</div>
<div class="post-social-media">


</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
<section class="section blog-single" v-if="this.$i18n.locale == 'en'">
<div class="container">
<div class="row">
<div class="col-lg-10 offset-lg-1 col-md-12 col-12">
<div class="single-inner">

<div class="post-details">
<div class="detail-inner">

<ul class="custom-flex post-meta">
<li>
<a href="javascript:void(0)">
<!-- <i class="lni lni-calendar"></i> -->
</a>
</li>
</ul>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" >Coming soon……</a>
</h2>
</div>
</div>
</div>
</div>
</div>
</div>
</section>



 

<a href="javascript:void(0)" class="scroll-top btn-hover" @click="scrollToAnchor('top')">
<i class="lni lni-chevron-up"></i>
</a>
  </div>
</template>
  
<script>
import indexnav from "@/components/nav/indexnav.vue";
import banner from "@/components/header/banner.vue";

export default {
  name: "RegisterMetting",
  components: {
    indexnav,
    banner,
  },
  data() {
    return {
      bannerUrl: ["BANNER.png"],
    };
  },
  mounted() {
    //  TouchSlide({
    // 		slideCell:"#news_slide",
    // 		mainCell:".bd ul",
    // 		effect:"leftLoop",
    // 		autoPlay:true//自动播放
    //  });
    const slide = document.querySelector(".hero-slider");
    console.log(slide);
    tns({
      container: ".hero-slider",
      items: 1,
      slideBy: "page",
      autoplay: false,
      mouseDrag: true,
      gutter: 0,
      nav: true,
      controls: false,
      controlsText: [
        '<i class="lni lni-arrow-left"></i>',
        '<i class="lni lni-arrow-right"></i>',
      ],
    });

    document.addEventListener("click", (evnt) => {
      let anchor = evnt.target.closest('a[href^="#"]');
      if (anchor) {
        evnt.preventDefault();
        let value = anchor.getAttribute("set_data");
        if (value) {
          this.newsDetail(value);
        }
      }
    });
  },
  methods: {
    downLoadFile(fileName) {
      const fileUrl = "/img/" + fileName;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileName);
      link.click();
    },
    goToIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    scrollToAnchor(id) {
      var anchor = document.getElementById(id);
      anchor.scrollIntoView();
    },
    newsDetail(index) {
      console.log(11111);
      this.$router.push({
        path: "/newsDetail/" + index,
      });
    },
    caseDetail(type) {
      this.$router.push({
        path: "/caseDetail/" + type,
      });
    },
  },
};
</script>
  
<style>
.sub_title {
  display: inline-block;
  width: 75px;
  color: #888;
}
.sub_title1 {
  display: inline-block;
  width: 90px;
  color: #888;
  margin-left: 5px;
}
table {
  width: 400px;
  margin-left: 30px;
  border: 1px solid #000000;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #000000;
  text-align: center;
}
</style>