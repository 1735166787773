<template>
  <div id="top">
	<!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
<div class="hero-slider">
    <div class="breadcrumbs overlay" :style="{backgroundImage: 'url(' + require('../assets/images/banner01.jpg') + ')' }">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-8 offset-lg-2 col-md-12 col-12">
<div class="breadcrumbs-content">
<h1 class="page-title">{{ $t("banners.committee.h1Content") }}</h1>
<p>{{ $t("banners.committee.content") }}</p>
</div>
<ul class="breadcrumb-nav">
<li><a style="cursor:pointer" @click="goToIndex()">{{ $t("banners.committee.button[0]") }}</a></li>
<li>{{ $t("banners.committee.button[1]") }}</li>
</ul>
</div>
</div>
</div>
</div>
</div>  
</section>

<section class="section blog-single">
<div class="container">
<div class="row">
<div class="col-lg-10 offset-lg-1 col-md-12 col-12">
<div class="single-inner">

<div class="post-details">
<div class="detail-inner">

<ul class="custom-flex post-meta">
<li>
<!-- <a href="javascript:void(0)">
<i class="lni lni-calendar"></i>
2024年07月24日
</a> -->
</li>
</ul>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" >{{ $t("committeePage.organization1") }} </a>
</h2>
<h3 style="font-weight: 400;margin-top: 5px;text-align: center;">{{ $t("committeePage.name1") }}</h3>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" style="text-align: center;">{{ $t("committeePage.organization2") }} </a>
</h2>
<h3 style="font-weight: 400;margin-top: 5px;text-align: center;">{{ $t("committeePage.name2") }}</h3>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" style="text-align: center;">{{ $t("committeePage.organization3") }} </a>
</h2>
<h3 style="font-weight: 400;margin-top: 5px;text-align: center;">{{ $t("committeePage.name3") }} </h3>
<h2 class="post-title" style="text-align: center;">
<a href="javascript:void(0)" style="text-align: center;">{{ $t("committeePage.organization4") }} </a>
</h2>
<h3 style="font-weight: 400;margin-top: 5px;text-align: center;">{{ $t("committeePage.name4") }}</h3>
<div class="post-tags-media">

<div class="post-social-media">


</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>



 

<a href="javascript:void(0)" class="scroll-top btn-hover" @click="scrollToAnchor('top')">
<i class="lni lni-chevron-up"></i>
</a>
  </div>
</template>
  
<script>
 
  
  import indexnav from "@/components/nav/indexnav.vue"
  import banner from "@/components/header/banner.vue"


export default {
   name:"IndexPage",
   components:{
    indexnav,
	banner
   },
   data(){
    return{
		bannerUrl:["BANNER.png"]
    }
   },
   mounted(){
  	//  TouchSlide({ 
	// 		slideCell:"#news_slide",
	// 		mainCell:".bd ul",
	// 		effect:"leftLoop",
	// 		autoPlay:true//自动播放
	//  });
    const slide = document.querySelector(".hero-slider");
    console.log(slide)
	   tns({
            container: '.hero-slider',
            items: 1,
            slideBy: 'page',
            autoplay: false,
            mouseDrag: true,
            gutter: 0,
            nav: true,
            controls: false,
            controlsText: ['<i class="lni lni-arrow-left"></i>', '<i class="lni lni-arrow-right"></i>'],
        });
	

	 document.addEventListener('click', evnt => {
		let anchor = evnt.target.closest('a[href^="#"]')
		if (anchor) {
			evnt.preventDefault()
			let value = anchor.getAttribute('set_data');
		    if(value){
 			  this.newsDetail(value)
			}
           
        }
        })
 },
 methods: {
    downLoadFile(fileName){
        const fileUrl =  '/img/'+fileName;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download',fileName);
        link.click();
    },
       goToIndex(){
       this.$router.push({
          path:"/index"
        })
    },
    scrollToAnchor(id){
        var anchor = document.getElementById(id);
        anchor.scrollIntoView();
    },
	 newsDetail(index){
		console.log(11111)
        this.$router.push({
          path:"/newsDetail/"+index
        })
     },
	 caseDetail(type){
     this.$router.push({
          path:"/caseDetail/"+type
      })
    }
 }
    
}
</script>
  
<style>

    
    

</style>