<template>
  <div id="top">
    <!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
      <div class="hero-slider">
        <!-- <div class="hero-inner overlay" style="background-image: url(../assets/images/banner01.jpg);"> -->
        <div
          class="hero-inner overlay"
          :style="{
            backgroundImage:
              'url(' + require('../assets/images/banner01.jpg') + ')',
          }"
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-8 offset-lg-2 col-md-12 co-12">
                <div class="home-slider">
                  <div class="hero-text">
                    <h5 class="wow fadeInUp" data-wow-delay=".3s">
                      {{ $t("banners.home.title") }}
                    </h5>
                    <h1 class="wow fadeInUp" data-wow-delay=".5s">
                      {{ $t("banners.home.h1Content") }}
                    </h1>
                    <p class="wow fadeInUp" data-wow-delay=".7s">
                     {{ $t("banners.home.content") }}
                    </p>
                    <div class="button wow fadeInUp" data-wow-delay=".9s">
                      <a
                        style="cursor: pointer"
                        @click="goToCallMetting()"
                        class="btn"
                        > {{ $t("banners.home.button[0]") }}</a
                      >
                      <a
                        style="cursor: pointer"
                        @click="goToCallMetting()"
                        class="btn alt-btn"
                        >{{ $t("banners.home.button[1]") }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="hero-inner overlay" style="background-image: url('static/image/slider-bg2.jpg');">
<div class="container">
<div class="row ">
<div class="col-lg-8 offset-lg-2 col-md-12 co-12">
<div class="home-slider">
<div class="hero-text">
<h5 class="wow fadeInUp" data-wow-delay=".3s">Start to learning Today</h5>
<h1 class="wow fadeInUp" data-wow-delay=".5s">Innovation Paradise<br> For Students </h1>
<p class="wow fadeInUp" data-wow-delay=".7s">Lorem Ipsum is simply dummy text of the
printing and typesetting <br> industry. Lorem Ipsum has been the industry's
standard
<br>dummy text ever since an to impression.</p>
<div class="button wow fadeInUp" data-wow-delay=".9s">
<a href="about-us.html" class="btn">Learn More</a>
<a href="events-grid.html" class="btn alt-btn">Our Events</a>
</div>
</div>
</div>
</div>
</div>
</div>-->
      </div>
    </section>

    <section class="features">
      <div class="container-fluid">
        <div class="single-head">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-12 padding-zero">
              <div class="single-feature">
                <h3><a href="javascript:void(0)">{{ $t("indexPage.important1.title") }}</a></h3>
                <p>
                  {{ $t("indexPage.important1.content") }}
                </p>
                <div class="button">
                  <a
                    style="cursor: pointer"
                    @click="goToCallMetting()"
                    class="btn"
                    > {{ $t("indexPage.important1.button") }} <i class="lni lni-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12 padding-zero">
              <div class="single-feature">
                <h3><a href="javascript:void(0)">{{ $t("indexPage.important2.title") }}</a></h3>
                <p>{{ $t("indexPage.important2.content") }}<br />{{ $t("indexPage.important2.content1") }}</p>
                <div class="button">
                  <a
                    style="cursor: pointer"
                    @click="goToCallForPaper()"
                    class="btn"
                    >{{ $t("indexPage.important2.button") }} <i class="lni lni-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12 padding-zero">
              <div class="single-feature last">
                <h3><a href="javascript:void(0)">{{ $t("indexPage.important3.title") }}</a></h3>
                <p>
                  {{ $t("indexPage.important3.content") }}
                  <br />{{ $t("indexPage.important3.content1") }}
                </p>
                <div class="button">
                  <a
                    style="cursor: pointer"
                    @click="goToCallMetting()"
                    class="btn"
                    >{{ $t("indexPage.important3.button") }} <i class="lni lni-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="about-left">
              <div class="about-title align-left">
                <span class="wow fadeInDown" data-wow-delay=".2s"
                  >{{ $t("indexPage.button") }}</span
                >
                <h2 class="wow fadeInUp" data-wow-delay=".4s">
                   <!-- {{ article.title.length >12 ? suarticle.title.substring(0, 12):article.title}} <br /> {{ article.title.length >12 ? suarticle.title.substring(12):''}} -->

                  {{ article.title}}
                </h2>
                <p class="wow fadeInUp" data-wow-delay=".6s">
                  {{ article.description }}
                </p>
                <!-- <p class="qote wow fadeInUp" data-wow-delay=".8s">Lorem ipsum dolor sit amet, consectetur
adipisicing elit, do eius mod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                <div class="button wow fadeInUp" data-wow-delay="1s">
                  <a
                    style="cursor: pointer"
                    @click="goToHistoryNews()"
                    class="btn"
                    >{{ $t("indexPage.button1") }}</a
                  >
                  <!-- <a href="javascript:;" class="glightbox video btn"> Play Video<i class="lni lni-play"></i></a> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="about-right wow fadeInRight" data-wow-delay=".4s">
              <!-- <img src="../assets/images/about-img2.png" alt="#"> -->
              <img
                :src=article.coverImage
                alt="#"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="client-logo-section">
      <div class="container">
        <div class="client-logo-wrapper">
          <div
            class="client-logo-carousel d-flex align-items-center justify-content-between"
          >
            <div class="client-logo">
              <img src="../assets/images/client1-01.png" alt="" />
            </div>
            <div class="client-logo">
              <img src="../assets/images/client1-02.png" alt="" />
            </div>
            <div class="client-logo">
              <img src="../assets/images/client1-03.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      href="javascript:void(0)"
      class="scroll-top btn-hover"
      @click="scrollToAnchor('top')"
    >
      <i class="lni lni-chevron-up"></i>
    </a>
  </div>
</template>
  
<script>
import indexnav from "@/components/nav/indexnav.vue";
import banner from "@/components/header/banner.vue";
import { newsDetail, newsIndexLatest } from "@/api/api";

export default {
  name: "IndexPage",
  components: {
    indexnav,
    banner,
  },
  data() {
    return {
      bannerUrl: ["BANNER.png"],
      article: {}
    };
  },
  created() {
    this.getLatestDetail();
  },

watch: {
    '$i18n.locale'(newValue,oldValue) {
      this.getLatestDetail() 
    }
  },
  mounted() {
    //  TouchSlide({
    // 		slideCell:"#news_slide",
    // 		mainCell:".bd ul",
    // 		effect:"leftLoop",
    // 		autoPlay:true//自动播放
    //  });
    const slide = document.querySelector(".hero-slider");
    console.log(slide);
    tns({
      container: ".hero-slider",
      items: 1,
      slideBy: "page",
      autoplay: false,
      mouseDrag: true,
      gutter: 0,
      nav: true,
      controls: false,
      controlsText: [
        '<i class="lni lni-arrow-left"></i>',
        '<i class="lni lni-arrow-right"></i>',
      ],
    });

    tns({
      container: ".client-logo-carousel",
      slideBy: "page",
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      gutter: 15,
      nav: false,
      controls: false,
      responsive: {
        0: {
          items: 1,
        },
        540: {
          items: 3,
        },
        768: {
          items: 4,
        },
        992: {
          items: 4,
        },
        1170: {
          items: 6,
        },
      },
    });
    document.addEventListener("click", (evnt) => {
      let anchor = evnt.target.closest('a[href^="#"]');
      if (anchor) {
        evnt.preventDefault();
        let value = anchor.getAttribute("set_data");
        if (value) {
          this.newsDetail(value);
        }
      }
    });
  },
  methods: {
    goToCallForPaper() {
      this.$router.push({
        path: "/call",
      });
    },
    goToCallMetting() {
      this.$router.push({
        path: "/metting",
      });
    },
    goToHistoryNews() {
      this.$router.push({
        path: "/aboutHis/news",
      });
    },
    scrollToAnchor(id) {
      var anchor = document.getElementById(id);
      anchor.scrollIntoView();
    },
    newsDetail(index) {
      console.log(11111);
      this.$router.push({
        path: "/newsDetail/" + index,
      });
    },
    caseDetail(type) {
      this.$router.push({
        path: "/caseDetail/" + type,
      });
    },
    //查询最近会议
    getLatestDetail() {
      newsIndexLatest().then((response) => {
         console.log(response)
        if (response.code == 0) {
          this.article = response.data;
        }
        that.loading = false;
      });
    },
  },
};
</script>
  
<style>
</style>