<template>
    <div>
    <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle"></banner>

    <div class="com_zi clearfix">
		<div class="left_zi">
			<div class="top_com">
				<span>产品展示</span>
			</div>
			<ul class="nav_left">
				<li :class="{'active':type == 1}">
					<a @click="checkType(1)">在线监测设备</a>
				</li>
				<li :class="{'active':type == 2}">
					<a @click="checkType(2)">便携检测设备</a>
				</li>
				<li :class="{'active':type == 3}">
					<a @click="checkType(3)">卵囊藻自动扩培机</a>
				</li>
			</ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a @click="checkType(1)">产品中心</a> > <a >{{ productName}}</a> </span>
			</div>
        <div  v-if="type == 1" class="pro_zi_show">
            <div class="pro_show1 clearfix">
					    <div >
						    <h4>Y53水产在线监测控制仪</h4>
						    <p>远程、智能控制增氧机Y53溶氧套餐荧光法水产养殖专用。</p>
					    </div>
				    </div>
				    <div class="pro_show2">
					    <div class="top_3">产品详情</div>
						  <!-- <img src="../../assets/images/FktcOBSLnMwEDlZcGlgnVZEpP6oM.jpg"  style="width: 100%;" />
						  <img src="../../assets/images/FnxC1OO7INjJ_E_ZSPjKvxo14WNl.jpg"  style="width: 100%;" />
						  <img src="../../assets/images/Ft_3B7ZXIet81rmA1w3rzG8KB_GK.jpg"  style="width: 100%;" />
						  <img src="../../assets/images/Fo357NARgqkXGhDj2fkarnTl-Kp4.jpg"  style="width: 100%;" /> -->
				    </div>
        </div>
        <div v-if="type == 2" class="pro_zi_show">
				  <div class="pro_show1 clearfix">
					  <div >
						  <h4>P3便携式水质检测仪</h4>
						  <p>P3产品是我司最新研发的便携式智能水质检测仪，主要针对水产养殖领域。该设备可检测十个水质指标，其中溶解氧、温度、pH通过设备蓝牙连接手机，在手机APP上可直接显示所测池塘的水质数据，数据可一键保存，数据云端存储不丢失，查询方便。设备所使用的荧光法溶解氧传感器采用业界领先的荧光淬息原理，检测精度高，抗干扰能力强，不受水流影响。另外七个指标氨氮、亚硝酸盐、总碱度、总硬度、钙离子、磷酸盐、生物量采用试剂检测、设备自动比色后APP端显示结果，避免人为比色造成的误差。设备携带方便，操作便携，检测数据云端存储，方便查询，是水产养殖的得力帮手。</p>
					  </div>
				  </div>
				  <div class="pro_show2">
					  <div class="top_3">产品详情</div>
						  <!-- <img src="../../assets/images/202312131722103c3045.jpg"  style="width: 100%;" />
						  <img src="../../assets/images/20231213172220286307.jpg"  style="width: 100%;" />
						  <img src="../../assets/images/Fvo_Ury3reePc2pT30HEBQXwk0Ie.jpg"  style="width: 90%;" />
						  <img src="../../assets/images/FvPDC7Pggp6uhW3jIhpPqkNyP9kS.jpg"  style="width: 90%;" />
						  <img src="../../assets/images/FmrCLarGz4fViOID8Mx1EGD7PDY6.jpg"  style="width: 90%;" />
						  <img src="../../assets/images/FuCpq-7xpEru6DD6Y8iLckR05upP.jpg"  style="width: 90%;" />
						  <img src="../../assets/images/FvVUH6sNzo6dH3LPJIJgz2OOfFH8.jpg"  style="width: 90%;" />
						  <img src="../../assets/images/FlG8QUaFAWtZ3sOkBpXXUcq0ZOLb.jpg"  style="width: 90%;" />
						  <img src="../../assets/images/Fnzi4WmnD3VtKi-DsTdtV-jlRBwJ.jpg"  style="width: 90%;" /> -->
				  </div>
			  </div>
        <div v-if="type == 3" class="pro_zi_show">
				<div class="pro_show1 clearfix">
					<div >
						<h4>卵囊藻自动扩培机</h4>
						<p> 1) 培藻效率高，培藻效率为5天10倍，效率是其他产品的2倍以上；
							<br/>
							2) 自动化程度高，真正做到一键扩培；
							<br/>
							3) 桶体采用高分子复合材料，透光率高和使用寿命长，桶体寿命长达10年；
							<br/>
							4) 触摸屏设计，操作简单，查看方便。</p>
					</div>
				</div>
				<div class="pro_show2">
					<div class="top_3">
						产品详情
					</div>
						<!-- <img src="../../assets/images/20200527162808d2214c.jpg"  style="width: 100%;" /> -->
				
				</div>
			</div>
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"

import { h } from 'vue'
export default {
    name:"IndexPage",
  components:{
    
    Banner,
    MyNav,
   
    
  },
  // beforeRouteUpdate(to,from,next){
  //   console.log(111111)
  //    this.detail = false;
  //   if(to.params.type != this.type){
  //     this.type = to.params.type;
  //   }
  //   next()
  // },

  data(){
    return {
      bannerUrl: [],
      type: 1,
      productName:'在线监测设备',
      divStyle: '',
      imgStyle: '',
      height: ''

    }
  },
  watch: {
    type(item1,item2){
      console.log("++++++++++++")
      if(item1 != item2){
        if(this.type == 1){
          this.productName = '在线监测设备'
        }else if(this.type == 2){
          this.productName = '便携检测设备'
        }else if(this.type == 3){
          this.productName = '卵囊藻自动扩培机'
        }
      }
    }
  },
  created(){
    if(this.$route.params){
      this.type = this.$route.params.type;
      let width = window.innerWidth;
      if(width > 960){
        this.height = '500px';
      }else {
        this.height = 'none'
      }
       this.productDetail(this.$route.params.type)
    }
  },
  mounted() {
     let width = window.innerWidth; // 屏幕宽度 
     if(width < 480){
       this.height = '250px'
     }else if(width < 640){
       this.height = '350px'
     }else if(width < 960){
        this.height = '425px'
     }
    
     console.log(this.height)
  },
  methods: {
    checkType(type){
     this.$router.push({
          path:"/product/"+type
      })
    },
    productDetail(type){
      if(type == 1){
        this.productName = '在线监测设备'
        this.bannerUrl = ["2019011516585676944CFEF7C8527F_b.jpg"]
        this.divStyle ="padding: 20px 10px;margin-top: 1%;width: 98%;margin: auto;max-width: 1200px;"
        this.imgStyle='width: initial;'
      }else if(type == 2){
        this.productName = '便携检测设备'
        this.bannerUrl = ["20231213172302674802DDC609611B_b.png","20231213172306F01A1E1E41C380A9_b.webp","20231213172306459016121CEE184A_b.jpg",
        "202312131723068ABACFCF5CC087BD_b.jpg","202312131723069BCC1DB9EB03C471_b.jpg"]
        this.divStyle ="padding: 20px 10px;margin-top: 1%;width: 98%;margin: auto;"
        this.imgStyle='width: initial;height:'+this.height
        console.log(this.imgStyle)
      }else if(type == 3){
        this.productName = '卵囊藻自动扩培机'
         this.bannerUrl = ["202005271628259BF180964ED5DCA3_b.jpg"]
        this.divStyle ="padding: 20px 10px;margin-top: 1%;width: 98%;margin: auto;max-width: 1200px;"
        this.imgStyle='width: initial;'
      }
    }
  }
}
</script>
  
<style>

    
    

</style>