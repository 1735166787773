var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header navbar-area"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"nav-inner"},[_c('nav',{staticClass:"navbar navbar-expand-lg"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":""},on:{"click":function($event){return _vm.toindex()}}},[_c('img',{attrs:{"src":this.$i18n.locale == 'zh' ? _vm.zhLogo : _vm.enLogo,"alt":"Logo"}})]),_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse sub-menu-bar",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ms-auto",attrs:{"id":"nav"}},_vm._l((_vm.$t('classify.title')),function(item,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('a',{class:[
                        'page-scroll',
                        'dd-menu',
                        'collapsed',
                        index == _vm.indexActive ||
                        (item.href != '' && _vm.path.indexOf(item.href) != -1)
                          ? 'active'
                          : '',
                      ],attrs:{"data-bs-toggle":"collapse","data-bs-target":'#submenu-1-' + (index + 1),"aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":function($event){return _vm.goToPage(item.href, index, 0)}}},[_vm._v(_vm._s(item.title))]),(item.child.length > 0)?_c('ul',{staticClass:"sub-menu collapse",attrs:{"id":'submenu-1-' + (index + 1)}},_vm._l((item.child),function(item1,index1){return _c('li',{key:index1,class:[
                          'nav-item',
                          index1 == _vm.indexActive1 ||
                          (item1.href != '' && _vm.path == item1.href)
                            ? 'active'
                            : '',
                        ]},[_c('a',{on:{"click":function($event){return _vm.goToPage(item1.href, index, index1)}}},[_vm._v(_vm._s(item1.title))])])}),0):_vm._e()])}),0),_vm._m(1),_c('div',{staticClass:"toolbar-social",staticStyle:{"margin-left":"15px"}},[_c('div',{staticClass:"menu"},[_c('a',{staticClass:"menu-item",staticStyle:{"color":"#6c757d"},attrs:{"href":"#"},on:{"click":function($event){return _vm.changeLang('zh')}}},[_vm._v("中文")]),_c('span',[_vm._v(" / ")]),_c('a',{staticClass:"menu-item",staticStyle:{"color":"#6c757d"},attrs:{"href":"#"},on:{"click":function($event){return _vm.changeLang('en')}}},[_vm._v("English")])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler mobile-menu-btn",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"toggler-icon"}),_c('span',{staticClass:"toggler-icon"}),_c('span',{staticClass:"toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"d-flex search-form"},[_c('input',{staticClass:"form-control me-2",attrs:{"type":"search","placeholder":"Search","aria-label":"Search"}}),_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit"}},[_c('i',{staticClass:"lni lni-search-alt"})])])
}]

export { render, staticRenderFns }