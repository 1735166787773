const zh = {
    logo: '../../assets/images/logo1-02.svg',
    classify:{ 
    	title: [{title: '主页',href:'/index',child: []},
                {title: '关于',href:'/about',child: [{title: '历届回顾',href:'/aboutHisNews'},{title: '最新动态',href:'/aboutHis/news'}]} ,
                {title: '组委会',href:'/committee',child: []},
                {title: '会议征稿',href:'/call',child: [{title: '论文提交',href:''}]} ,
                {title: '注册参会',href:'/metting',child: []} ,
                {title: '演讲嘉宾',href:'/speaker',child: []} ,
                {title: '联系我们',href:'/contentus',child: []} 
                
        ]     
    },
    banners: {
        home: {
            title: "会议通知",
            h1Content: '关于举办“2024第二届储能与智慧电站关键技术论坛”的会议通知',
            content: '关于举办“2024第二届储能与智慧电站关键技术论坛”的会议通知',
            button: ['了解更多','报名参会']
        },
        concern: {
            title: "",
            h1Content: '关于我们',
            content: '我们荣幸地宣布，2024年第二届储能与智慧电站关键技术论坛即将盛大召开！邀您共探储能发展新篇章！',
            button: ['首页','关于我们']
        },
        latest: {
            title: "",
            h1Content: '最新动态',
            content: '欢迎关注储能和智能电站关键技术论坛最新动态',
            button: ['首页','最新动态']
        },
        committee: {
            title: "",
            h1Content: '组委会',
            content: '我们荣幸地宣布，2024年第二届储能与智慧电站关键技术论坛即将盛大召开！邀您共探储能发展新篇章！',
            button: ['首页','组委会']
        },
        paper: {
            title: "",
            h1Content: '会议征稿',
            content: '我们荣幸地宣布，2024年第二届储能与智慧电站关键技术论坛即将盛大召开！邀您共探储能发展新篇章！',
            button: ['首页','组委会'] 
        },
        registration: {
            title: "",
            h1Content: '会议通知',
            content: '2024第二届储能与智慧电站关键技术论坛以“智慧赋能 驱动未来”为主题，将于2024年10月18日至10月20日在天津水游城丽筠酒店召开。',
            button: ['首页','注册参会'] 
        },
        guest: {
            title: "",
            h1Content: '演讲嘉宾',
            content: '历届储能与智慧电站关键技术论坛演讲嘉宾介绍',
            button: ['首页','演讲嘉宾'] 
        },
        contactUs: {
            title: "",
            h1Content: '联系我们',
            content: '我们荣幸地宣布，2024年第二届储能与智慧电站关键技术论坛即将盛大召开！邀您共探储能发展新篇章！',
            button: ['首页','联系我们'] 
        }
    },
    indexPage: {
        important1 : {
            title: '会议通知',
            content: '2024年第二届储能与智慧电站关键技术论坛将于2024年10月18日-10月20日在中国天津举行。',
            content1: '',
            button: '了解更多'
        },
        important2 : {
            title: '会议征稿',
            content: '多场景下储能的地位、',
            content1: '智慧电站及绿色电站关键技术',
            button: '了解更多'
        },
        important3 : {
            title: '重要日期',
            content: '大会报名截止时间：2024年10月14日',
            content1: '大会时间：2024年10月18-10月20日',
            button: '了解更多'
        },
        button: '历届回顾',
        button1: '了解更多'
    },
    aboutPage: {
        desc: '2024第二届储能与智慧电站关键技术论坛以“智慧赋能 驱动未来”为主题，将于2024年10月18日至10月20日在中国天津举行。2024第二届储能与智慧电站关键技术论坛旨在为我国储能技术、智能电网、智慧电站等领域的专家、学者和产业界人士搭建一个专业、创新的学术交流平台。会议将邀请中国工程院院士天津大学王成山教授团队、清华大学、北京航空航天大学、中国大唐集团、中国长江三峡集团上海勘测设计研究院、长江电力股份有限公司等知名学者和业内一线资深专家围绕“储能装备、水电装备、智慧电站、数字孪生、知识工程、智能设计、智能运维、增效扩容”等关键技术共同探讨，充分展示和分享创新理念和研究成果。',
        companyName: ['主办单位：','天津大学国家储能平台','天津仁爱学院','承办单位：','天津天发总厂机电设备有限公司','天津仁爱学院','协办单位：'
			,'中国水力发电工程学会继电保护与励磁专业委员会','天津机械工程学会','天津市电力工程协会','河北省水力发电工程学会','天津仁爱学院绿色水电研究院'
			,'支持媒体：','《机械设计》杂志社'],
        introduce1: {
                title1: '主办方',
                title2: '天津大学国家储能技术产教融合创新平台',
                content: [
                    '储能是新兴产业、国家战略。天津大学作为首批获批国家储能技术产教融合创新平台的三所高校之一，贯彻教育部、国家发改委指导方针， 聚焦国家能源战略布局，瞄准国际先进水平，依托新工科建设优势，整合高校和产业优质资源，推动储能技术领域人才培养以及科技创新，努力建设集 人才培养、学科建设、科技攻关三位一体的国家储能技术产教融合创新平台（以下简称“国家储能平台”）， 为国家培养未来能够引领储能技术进步与产业发展的卓越工程师和科学家。',
                    '天津大学国家储能平台2022年6月正式成立，项目投资总额为41655万元，集成电化学、 新材料、氢能、智能电网和储能经济与政策领域的优势力量，首批建设设计 电化学储能、燃料储能与应用、储能装备与系统、储能安全与运维和储能经济与政策 五个研究中心，以天津大学北洋园校区第56教学楼为核心功能区，结合化工学院、材料学院、机械学院、自动化学院等国家和省部级重点实验室改造建设。',
                    '天津大学国家储能平台2021年获批 储能科学与工程本科储能科学与工程本科专业并已招收第一届本科生，2022年获批储能科学与工程交叉学科硕士学位授权点，2023年获批培育博士学位授权点， 并依托未来技术学院开展储能人才培养，已经形成了有效的管理机制和完备的课程体系，有力支撑高层次人才培养。 2022年9月，天津大学获批实施储能技术国家急需高层次人才培养专项 建设高校，全国仅10所；“储能技术”也获批天津大学首批建设的6个校级学科交叉分中心 之一。平台突破现有专业、学院、行业的限制，现已形成以金东寒院士、王成山院士、赵天寿院士3名院士为带头人、 20余名国家级领军人才、40余名国家级青年人才领衔的跨学科、复合型、高层次的师资队伍，覆盖了储能的全环节和全链条。',
                    '国家储能平台聚焦“两性一度”，坚持 选拔、培养、评价、保障全方位谋划，专业、课程、教材、教法 全要素改革，构建具有天大特色的储能系统人才培养体系，加快培养储能领域“高精尖缺”人才。作为校内交叉创新的“策源高地”，平台实施产教融合协同育人计划， 以储能学科专业为主体（1），校内各优势学科为支撑、 （N），联合储能行业龙头企业（X），将产教融合、科教融合、协同育人理念贯穿人才培养全过程，构建 “1+N+X”产学研合作新模式， 增加学生早实践、多实践、高水平实践机会，探索更加符合卓越工程师成长规律的教育教学模式，建设具有天津大学特色的储能领域高端人才培养体系， 联合企业共同培养能够解决储能产业“卡脖子”技术和企业急需关键技术的优秀人才。平台已与国 家能源集团、中国能建、中石化、南方电网、比亚迪、东方电气、上海捷氢、玉柴芯蓝、天合储能 和金风科技 等企业签署人才联合培养协议，支持专项博士生参与企业的研发项目，并鼓励企业优秀青年技术骨干申请攻读定向博士生。',
                    '国家储能平台聚焦国家能源战略布局，坚持“发挥特色优势、服务区域发展，产学研教协同、实施开放共享”原则， 发挥地方区域性特色优势共享产业和教育资源结合，加强对储能领域卓越工程人才的培养，提升储能领域 电化学储能、燃料储能、储能装备与系统重点前沿方向的科研能力、科技攻关能力和水平，解决储能领域“卡脖子”问题和企业技术难题， 打通储能领域人才链、创新链、产业链、供应链，建设集学科建设、科技攻关、人才培养三位一体 的国家储能技术产教融合创新平台。',
                    '2023年，是我们党第二个百年奋斗目标的起步之年，是天津大学深入落实“十四五”规划承上启下、 加快高质量发展的关键时期，也是储能平台谋篇布局的关键一年。国家储能平台将以习近平新时代中国特色社会主义思想和党的二十大精神为指引， 深入贯彻落实教育部、国家发改委要求，聚焦人才培养、学科建设、科技攻关等平台重点工作，努力建设 国际一流的国家储能技术产教融合创新平台，为我国储能产业发展和国际竞争提供强有力的人才和技术支撑。'
                ]
        },
        introduce2: {
            title1: '',
            title2: '天津仁爱学院',
            content: [
                '天津仁爱学院前身天津大学仁爱学院，是2006年经教育部批准， 天津大学和天津市仁爱集团合作举办的本科层次全日制普通高等学校。2021 年经教育部批准依法转设为天津仁爱学院。',
                '近年来，学校在天津市教委的年检中排名第一， 连续三年在天津市高校就业质量考核中被评为“优秀”。连续两年在中国民办高校校友会排名第五。',
                '学校占地面积 1000 余亩，建筑面积约38万平方米。学校在校生近17000 人， 教职工千余人。学校以工科为主，目前拥有36个招生的本科专业。学校以培养应用型人才为目标，高度重视校企合作、 产学研基地的建设，与企业在产教融合、科教融通方面做出诸多成绩。学校与科大讯飞股份有限公司、中软国际教育科技集团等企业创办现代产业学院； 与天地伟业等170余家单位共建产学研基地及校外实践基地，为企业持续输送人才。学校连续三年突破年科研经费一亿元，拥有一大批业务精干的企业特派员， 为企业解决“卡脖子”问题。学校机械学科带头人张俊红教授团队荣获机械工业科学技术奖一等奖，是机械工业科技工作者的最高级别奖项。 学校作为理事长单位发起成立天津市技术经理人发展促进会，实现科研成果重大突破。',
                '转设后学校、仁爱集团与天津大学签订全面人才培养协议，仁爱集团每年向天津大学支出3000万资源使用费， 用于天津大学支持仁爱学院全面发展建设。近年来学校已选派多名优秀青年教师到天津大学攻读博士研究生，已选派200名优秀本科生到天津大学相关专业访学一年。 这也是仁爱集团与天津大学为学校搭建的人才成长“新赛道”。'
                ]
        },
        introduce3: {
            title1: '承办方',
            title2: '天津天发总厂机电设备有限公司',
            content: [
                '天津发电设备总厂始建于1938年，于1958年扩建，国家定点生产成套水力发电设备、 大型泵组的大型骨干企业。荣获国家、部委及天津市科技进步奖和优秀新产品奖。为我国水利、电力实业的发展做出了突出贡献。',
                '天津发电设备总厂发展并衍生出天津天发总厂机电设备有限公司、天发重型水电、天津阿尔斯通等几家水电设备专业制造公司及其他水利水电相关的制造商。',
                '六十余年来，天发系水电设备制造企业设计生产了混流式、贯流式、轴流式、抽水蓄能式成套水轮机组1500余套，其中贯流式机组800多台套， 是世界上生产大中型贯流机组最多的厂家，成为全球化的水电设备服务商。',
                '天津天发总厂机电设备有限公司传承天发系60多年的水电历史并将天发技术予以延续及提升，打造“4S店服务，10价值”全生命周期养护理念，调整产业和项目执行模式， 实行24小时快速响应机制，以满足市场和用户的需求。',
                '天津天发总厂始终坚持技术创新，现已是国家高新技术企业、国家科技型中小企业、天津市雏鹰企业，拥有多项专利和自有技术。',
                '天津天发总厂现已成为国电、华电、大唐、葛洲坝、华能、北方国际、中电投等央企合格供应商。截止到2020年底为300多个水电站提供了大修、改造、增效扩容、备品备件、 技术支持和服务、整机、整体方案优化等水电站4S店服务。'
                ]
        },
        introduce4: {
            title1: '协办方',
            title2: '中国水力发电工程学会继电保护与励磁专业委员会简介',
            content: [
                '中国水力发电工程学会继电保护与励磁专业委员会（以下简称专委会）成立于2005年5月18日， 创办伊始甫立宗旨——以更多更好的行业优秀工作者为依托，协助各水电行业的组织及企业开展好工作，为水电企业、社团和技术工作者，提供信息与合作交流的平台。 促进同行之间及时了解国内外最新技术前沿，共同探讨学科发展新成果和新进展，推动我国电厂继电保护及安全自动装置、励磁和直流系统等专业向更安全、更智能、 更高效方向发展。',
                '目前专委会直接联系和服务会员数量高达2万余人，广泛覆盖水电行业主要产学研相关机构，业已成为水电二次专业初具影响力的社会团体。经十余年的发展， 专委会影响力与日俱增。2016年11月2日换届，秘书单位设立在溪洛渡水力发电厂（世界第四大水电站）。专委会继往开来，紧抓行业热点和难点， 积极发挥桥梁和纽带作用，以技术交流研讨会、学术年会、技术培训班为载体，主动为产学研用各方搭建起广泛的学术交流平台，并依托三峡集团、 长江电力等水电龙头企业以及各委员单位的技术力量，组建了一支理论知识扎实、实践经验丰富的专家服务团队，先后开展了水电站状态检修、反事故安措研讨、 继电保护及励磁系统技术应用、继电保护精益化管理评价、数字化转型等技术领域的学术交流、技术培训以及各类标准修订发布，为推动水电自动化技术的研究和应用， 促进行业创新发展起到了积极的作用。'
            ]
        },
        introduce5: {
            title1: '',
            title2: '天津市机械工程学会',
            content: [
                '天津市机械工程学会成立于1951年，业务主管单位是天津市科学技术协会，是中国机械工程学会地方分会。 由机械工程相关专业大专院校、科研院所、企事业单位以及从事机械工程相关专业的专家、学者、技术人员组成的学术性非营利性的社团组织。 天津市机械工程学会业务范围包括学术交流、技术咨询服务、教育培训、编辑出版。',
                '天津市机械工程学会累计有全国会员5000余人，目前共有分会十四个，天津市机械工程学会及其分会在机械工程各个学术领域引领行业产业、创新发展。'
            ]
        },
        introduce6: {
            title1: '',
            title2: '天津市电力工程协会简介',
            content: [
                '天津市电力工程协会成立于2013年3月21日，登记机关为天津市社会团体管理局， 本会在天津市社会团体管理局的业务指导和监督管理下开展工作。协会以服务电力工程企业为宗旨，搭建电力工程企业与社会市场需求、 能源监管部门之间的桥梁和纽带。为会员提供政策法规、监管动态、行业新闻、协会咨询以及特种作业操作证培训等多方面服务，促进企业交流， 展现企业形象和风采。协会始终坚持以服务为中心，以需求为导向，以满足为标准，认真履行各项职责，全心全意为会员服务，为电力行业服务， 为电力行政部门排忧解难。'
            ]
        },
        introduce7: {
            title1: '',
            title2: '天津仁爱学院绿色水电研究院简介',
            content: [
                '天津仁爱学院绿色水电研究院以绿色能源、清洁能源装备为主要研究领域， 以绿色能源装备领域的技术创新理论、绿色能源产业创新与创业实践等为研究内容。聘请天津大学国家储能平台王成山院士为学术委员会主席、张俊红教授为专家委员会主席、 孙江教授为首席研究员、任成祖为院长、于洋洋为常务副院长、贺莹为院长。',
                '积极跟踪有关领域的国际国内研究动态，开展各级各类项目研究，定期组织相关领域的学术交流活动，多渠道发布研究成果，不断提升学校影响力。'
            ]
        },
        introduce8: {
            title1: '支持媒体',
            title2: '《机械设计》杂志社',
            content: [
                '《机械设计》于1983年创刊，1984年正式订刊名为《机械设计》，由中国科学技术协会批准和主管，中国机械工程学会、天津市机械工程学会等单位主办， 是我国机械行业类国家级综合性学术期刊，中国机械工程学会机械设计分会会刊、中文核心期刊、中国科学引文数据库（CSCD）收录期刊、 学位与研究生教育指定中文中重要期刊、中国科技论文统计源期刊等。《机械设计》杂志影响力指数及影响因子等指标逐年攀升，在同类科技期刊中名列前茅， 多次荣获天津市一级期刊、天津市优秀期刊奖。2020年入选机械工程领域高质量科技期刊分级目录。',
                '《机械设计》第五届编委会共有编委94人，包括主任委员1人，副主任委员5人，编委中拥有院士11人。 《机械设计》主编为谭建荣院士，执行主编、社长为王莹正高级工程师，副社长为陈文华教授、冯春生教授。',
                '2019年《机械设计》开办“创新设计”栏目，先后向路甬祥、潘云鹤、徐志磊、谭建荣等创新设计的领军院士专家约稿，引起了设计学术界的广泛关注。',
                '2022年《机械设计》刊登论文“面向智能制造全价值链的精益数字孪生体”、“数据驱动的产品创新设计研究”、“群智设计新思维”三篇论文获得“中国机械工程学会优秀论文”。 《机械设计》编委肖人彬教授论文“数据驱动的产品创新设计研究”入选学术精要高PCSI论文。',
                '《机械设计》影响因子在同类科技期刊中名列前茅，学术影响力、影响因子，被引频次不断提升，入选机械工程领域高质量科技期刊分级目录。围绕学科建设，连年开展学术活动、 决策咨询课题、 科普活动、为广大会员提供知识更新与培训服务，形成了多项品牌活动。'
            ]
        },
    },
    committeePage: {
        organization1: '会议主席',
        name1: '孙江',
        organization2: '技术方案委员会',
        name2: '张俊红、李金伟、于洋洋',
        organization3: '组织委员会',
        name3: '任成祖、郭伟、闫飞、贺莹、刘金剑、石丽雯、靳艳梅',
        organization4: '出版主席',
        name4: '王莹',
    },
    callPage: {
        date: '2024年07月24日',
        title1: '【 第二届储能与智慧电站关键技术论坛】会议征稿启事',
        content1: [
            '各位储能与智慧电站领域的专家学者、工程师、科研工作者们，注意啦！',
            '我们荣幸地宣布，2024年第二届储能与智慧电站关键技术论坛即将盛大召开！邀您共探储能发展新篇章！',
            '会议时间：2024年10月18日至20日',
            '预投稿报名截止时间：2024年7月30日',
            '全文截稿时间：2024年9月10日',
            '本次论坛由天津大学储能中心联合天津仁爱学院主办，旨在推动储能技术与智慧电站的创新与发展，为实现国家的“3060双碳目标”贡献力量。诚邀各界共同探讨“储能装备、水电装备、智慧电站、数字孪生、知识工程、智能设计、智能运维、增效扩容”的关键技术。'
        ],
        title2: '征稿主题涵盖：',
        content2: [
            '多场景下储能的地位',
            '智慧电站及绿色电站关键技术',
            '征稿内容包括但不限于：',
            '储能领域综述',
            '专题论文',
            '储能技术与实例分析',
            '工业设计论坛与资讯',
            '2-3位专家审稿，确保稿件质量，录用论文将在《机械设计》增刊发表，版面费每页1000元。'
        ],
        title3: '【联系方式】',
        content3: [
            '投稿邮箱：icestj@126.com'
        ]
    },
    speakerPage: {
        year24: [
            {
                title: '2024年大会主持人',
                name: '孙江',
                designation: '大会主席、天津仁爱学院领军人才、教授、博士、天发总厂董事长、深圳恒发水电公司总经理。天津首批“优秀企业家培养工程”等人才工程。天津仁爱学院领军人才。曾荣获天津市五一劳动奖章、中国机械工业优秀企业家等荣誉称号。主持国家级、省部级科研创新项目12项，其中EI以上6篇，实用新型、发明专利近50项。',
                picUrl: require('@/assets/images/metting_user06.png')
            },
            {
                title: '2024年主讲嘉宾',
                name: '郭力',
                designation: '天津大学电气自动化与信息工程学院教授、博士生导师。天津大学国家储能创新平台储能应用中心副主任，微网与智能配电系统开发与应用国家地方联合工程研究中心副主任，中国电机工程学会新能源并网专委会委员。长期围绕新能源发电、锂离子电池储能系统以及新型电力系统的快速稳定控制和优化运行开展研究工作。',
                picUrl: require('@/assets/images/guoli.png')
            },
            {
                title: '2024年主讲嘉宾',
                name: '王正伟',
                designation: '天津大学电气自动化与信息工程学院教授、博士生导师。天津大学国家储能创新平台储能应用中心副主任，微网与智能配电系统开发与应用国家地方联合工程研究中心副主任，中国电机工程学会新能源并网专委会委员。长期围绕新能源发电、锂离子电池储能系统以及新型电力系统的快速稳定控制和优化运行开展研究工作。',
                picUrl: require('@/assets/images/metting_user2.png')
            },
            {
                title: '2024年主讲嘉宾',
                name: '刘继红',
                designation: '北京航天航空大学教授，博士生导师。曾先后获得华中科技大学、北京理工大学、东京都立大学学士硕士博士学位。主要研究方向及特色是现代设计理论与方法、数字化设计与制造技术、飞机制造技术、人工智能及其工程应用。中国计算机学会计算机辅助设计与图形学专业委员会常委、国家科技部“十二五”制造业信息化工程总体专家组成员。',
                picUrl: require('@/assets/images/liujihong.png')
            },
            {
                title: '2024年主讲嘉宾',
                name: '李彦浩',
                designation: '中国长江三峡集团上海勘测设计研究院有限公司高级工程师。中国电力技术市场协会优秀青年科技人才。扎根国内外重大水电工程勘测设计和科研一线，主持参与了乌干达伊辛巴、尼泊尔KGG、尼泊尔塔马柯西三、西藏紫霞水电站以及重庆菜籽坝、巫山大溪、海南羊林等抽水蓄能电站的水力机械科技攻关，发表高水平论文7篇，参编行业标准2项，获得国家、国际行业协会、集团公司等科技奖项6项。',
                picUrl: require('@/assets/images/liyanhao.png')
            },
            {
                title: '2024年主讲嘉宾',
                name: '周志军',
                designation: '长江电力股份有限公司科学技术研究中心主任研究员、高级工程师。曾在长江三峡能事达股份有限公司担任调速事业部总经理、公司副总经理。长期从事水轮机控制及液压控制技术研发工作，主持参与了国家、省部级多项重大项目研发工作，其中主持公关完成国产700MW级水轮机调速器设备。获得国家科技进步二等奖1项，省部级科技奖2项，集团级科技奖2项。',
                picUrl: require('@/assets/images/zhouzhijun.png')
            },
            {
                title: '2024年主讲嘉宾',
                name: '张海库',
                designation: '大唐水电科学技术研究院有限公司正高级工程师，工学博士。中国大唐集团首届青年拔尖人才，全国水轮机标准化委员会委员、中国水力发电学会理事、中国电工学会高级会员、西藏自治区水力发电工程学会金结机电专业委员会委员、能源动力重大装备及技术四川省协同创新中心委员，《大电机技术》中国科技核心期刊青年编辑委员会委员、《水电与新能源》编委会委员。',
                picUrl: require('@/assets/images/zhanghaiku.png')
            },
            {
                title: '2024年主讲嘉宾',
                name: '李天',
                designation: '天津排放权交易所董事，总经理，复旦大学MBA。曾在银行、保险、互联网企业工作多年。在蚂蚁集团工作期间，作为生活缴费行业总经理，带领团队打造了中国最大的线上缴费平台。2019年12月，出任天津排放权交易所有限公司总经理。天津排放权交易所是全国首批综合性环境权益交易机构，推动市场体系建设，已逐步成为服务政、产、学、研及市场相关方推进绿色低碳高质量发展的重要市场平台。',
                picUrl: require('@/assets/images/litian.png')
            },
        ],
        speakerYears: ['2024','2023'],
        year23: [
            {
                title: '2023年特邀嘉宾',
                name: '王成山',
                designation: '中国工程院院士。现任智能电网教育部重点实验室主任，微网与智能配电系统国家地方联合工程研究中心主任;是教育部长江学者特聘教授、国家杰出青年基金获得者、“国家特支计划”百千万工程领军人才;兼任教育部科学技术委员会委员，中国能源研究会常务理事，《IETEnergy SystemsIntegration》主编。',
                picUrl: require('@/assets/images/metting_user1.png')
            },
            {
                title: '2023年主讲嘉宾',
                name: '王正伟',
                designation: '清华大学流体机械及工程研究团队负责人，长聘教授，博士生导师。IAHR 水力机械与系统方向学术委员会委员及亚洲分会主席，中国动力工程学会水轮机专业委员会副主任委员，全国水轮机标准化技术委员会委员，中国可再生能源学会理事，中国可再生能源学会海洋能专业委员会副主任委员。',
                picUrl: require('@/assets/images/metting_user2.png')
            },
            {
                title: '2023年主讲嘉宾',
                name: '刘新天',
                designation: '哈尔滨电机厂有限责任公司副总工程师、副总设计师/技术专家，正高级工程师。主要研究方向:水轮发电机设计、初步设计、现场安装问题处理，调试问题处理等;曾主持并设计湖北水布垭发电机460MW,曾荣获黑龙江省政府颁发科技技术进步奖二等奖;中国水力发电工程学会科技技术进步奖二等奖。',
                picUrl: require('@/assets/images/metting_user03.png')
            },
            {
                title: '2023年主讲嘉宾',
                name: '郭伟',
                designation: '天津仁爱学院机械工程学院院长，天津大学机械工程学院长聘教授、机械工程及管理科学工程博士生导师，博士，天津大学内燃机与工程热物理博士后流动站博士后，香港科技大学工业工程与工程管理系博士后。科技部制造业信息化科技工程总体专家组专家，中国机械工程学会工业工程师资格认证专家组专家。',
                picUrl: require('@/assets/images/metting_user04.png')
            },
            {
                title: '2023年主讲嘉宾',
                name: '李明涛',
                designation: '博士，西安交通大学新能源科学与工程系副教授，博士生导师。现任中国节能协会公共机构节约能源资源专业委员会委员、中国化工学会过程模拟及仿真专业委员会青年委员、陕西省电力新能源及电动交通标准化委员会委员。开源组织Ai4Energy(https://github.com/ai4energy)创始人。',
                picUrl: require('@/assets/images/metting_user05.png')
            },
            {
                title: '2023年主讲嘉宾',
                name: '孙江',
                designation: '大会主席、天津仁爱学院领军人才、教授、博士、天发总厂董事长、深圳恒发水电公司总经理。天津首批“优秀企业家培养工程”等人才工程。天津仁爱学院领军人才。曾荣获天津市五一劳动奖章、中国机械工业优秀企业家等荣誉称号。主持国家级、省部级科研创新项目12项，其中EI以上6篇，实用新型、发明专利近50项。',
                picUrl: require('@/assets/images/metting_user06.png')
            },
            {
                title: '2023年主讲嘉宾',
                name: '霍乾涛',
                designation: '南瑞集团有限公司电控分公司副总经理，高级工程师。长期从事发电机励磁、调相机控制、新能源主动支撑等方面的研究工作，火电机组励磁系统等研制、设计及现场试验工作。提出了数字离散化控制模型的精确化方法，完成模型测试与入网检测等，并通过CE认证。历获中国电机工程学会中国电力科学技术奖一等奖。',
                picUrl: require('@/assets/images/metting_user07.png')
            },
            {
                title: '2023年标准宣贯嘉宾',
                name: '周林',
                designation: '澳大利亚RIZNER有限公司执行董事，中国水力发电工程学会继保与励磁专委会委员，澳大利亚工程师协会会员MIEAuSt。周林长期从事电力系统保护控制及在线监测方面的研究工作，获电弧光保护相关专利技术7项;近期重点项目包括三峡集团的乌东德和白鹤滩巨型水电站的应用。',
                picUrl: require('@/assets/images/metting_user08.png')
            },
            {
                title: '2023年标准宣贯嘉宾',
                name: '罗志昭',
                designation: '广东日昭电工有限公司董事长，系节能铜包铝管母线第一发明人。专业从事节能母线与绝缘配合的开发设计，研究与应用。发明了节能铜包铝管母线技术，获得国内外授权发明专利100多项。获得中国专利优秀奖2项等荣誉，主持制定企业标准2项，地方标准2项，团体标准2项。',
                picUrl: require('@/assets/images/metting_user09.png')
            },
        ]
    },
    newDetailPage: {
        name1: '分类',
        name2: '分享',
        name3: '打开微信“扫一扫”，打开网页后点击屏幕右上角分享按钮'
    },
    newsPage: {
        name1: '阅读更多',
        name2: '历届会议',
        name3: '分类',
    },
    contactUsPage: {
        contact1: {
            title: '会务组电话',
            content: ['022-26995595']
        },
        contact2: {
            title: '会议联系人',
            content: ['靳艳梅 电话：15900270919','金焕成 电话：13820434300']
        },
    }
}
export default zh;