<template>
  <div id="top">
    <!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
      <div class="hero-slider">
        <!-- <div class="hero-inner overlay" style="background-image: url(../assets/images/banner01.jpg);"> -->
        <div
          class="breadcrumbs overlay"
          :style="{
            backgroundImage:
              'url(' + require('../../assets/images/callforpaperBg.jpg') + ')',
          }"
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">{{ $t("banners.latest.h1Content") }}</h1>
                  <p>{{ $t("banners.latest.content") }}</p>
                </div>
                <ul class="breadcrumb-nav">
                  <li><a @click="goToIndex()">{{ $t("banners.latest.button[0]") }}</a></li>
                  <li>{{ $t("banners.latest.button[1]") }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section blog-single">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
            <div class="single-inner">
              <div class="post-thumbnils">
                <img :src="details.coverImage" alt="#" />
              </div>
              <div class="post-details">
                <div class="detail-inner">
                  <ul class="custom-flex post-meta">
                    <li>
                      <a href="javascript:void(0)">
                        <i class="lni lni-calendar"></i>
                        {{ fmtime(details.createTime) }}
                      </a>
                    </li>
                  </ul>
                  <h2 class="post-title">
                    <a href="javascript:void(0)">{{ details.title }}</a>
                  </h2>

                  <div class="news_content" v-html="details.content"></div>
                  <div class="post-tags-media">
                    <div class="post-tags popular-tag-widget mb-xl-40">
                      <h5 class="tag-title">{{ $t("newDetailPage.name1") }}</h5>
                      <div class="tags">
                        <a href="javascript:void(0)">{{ details.title }}</a>
                      </div>
                    </div>
                    <div class="post-social-media">
                      <h5 class="share-title">{{ $t("newDetailPage.name2") }}</h5>
                      <ul class="custom-flex">
                        <li @click="showCode()" style="position: relative">
                          <a
                            href="javascript:void(0)"
                            class="facebook"
                            style="background-color: #fbfbfb"
                          >
                            <i
                              ><img
                                src="../../assets/images/wechart.png"
                                style="height: 100%; width: 100%"
                            /></i>
                          </a>
                          <div
                            class="share_code"
                            :style="
                              isShow == 0
                                ? 'visibility: hidden;'
                                : 'visibility: visible;'
                            "
                          >
                           {{ $t("newDetailPage.name3") }}
                            <div class="qrcode" ref="qrCodeUrl"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <a
      href="javascript:void(0)"
      class="scroll-top btn-hover"
      @click="scrollToAnchor('top')"
    >
      <i class="lni lni-chevron-up"></i>
    </a>
  </div>
</template>
  
<script>
import Banner from "@/components/header/banner.vue";
import MyNav from "@/components/nav/nav.vue";
import { newsDetail, newsLatestDetail } from "@/api/api";
import { formatDates, formatDate } from "@/utils/index";
import QRCode from "qrcodejs2";

export default {
  name: "IndexPage",
  components: {
    Banner,
    MyNav,
  },
  // beforeRouteUpdate(to,from,next){
  //   console.log(111111)
  //    this.detail = false;
  //   if(to.params.type != this.type){
  //     this.type = to.params.type;
  //   }
  //   next()
  // },

  data() {
    return {
      details: {},
      isShow: 0,
    };
  },

  created() {
    console.log("==============")
    console.log(this.$route.params)
    if (this.$route.params && this.$route.params.index) {
      let index = parseInt(this.$route.params.index);
      this.getDetailById(index);
    } else {
      this.getLatestDetail();
    }
  },
    watch: {
    '$i18n.locale'(newValue,oldValue) {
      if (this.$route.params) {
      let index = parseInt(this.$route.params.index);
      this.getDetailById(index);
    } else {
      this.getLatestDetail();
    }
    }
  },
  mounted() {
    this.creatQrCode();
  },
  methods: {
    scrollToAnchor(id) {
      var anchor = document.getElementById(id);
      anchor.scrollIntoView();
    },
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: window.location.href,
        width: 75,
        height: 75,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    showCode() {
      this.isShow = !this.isShow;
    },
    getDetailById(id) {
      let query = {
        id: id,
      };
      newsDetail(query).then((response) => {
        if (response.code == 0) {
          this.details = response.data;
        }
        that.loading = false;
      });
    },
    getLatestDetail() {
      newsLatestDetail().then((response) => {
        if (response.code == 0) {
          this.details = response.data;
        }
        that.loading = false;
      });
    },
    toNews(type) {
      this.$router.push({
        path: "/new/" + type,
      });
    },

    upPage() {
      if (this.index == 1) {
        return;
      } else {
        this.index = this.index - 1;
        this.lastTitle = this.title[this.index - 1];
        this.nextTitle = this.title[this.index + 1];
      }
    },
    downPage() {
      if (this.index == 5) {
        return;
      } else {
        this.index = this.index + 1;
        this.lastTitle = this.title[this.index - 1];
        this.nextTitle = this.title[this.index + 1];
      }
    },
    fmtime(val) {
      return formatDates(new Date(val), "yyyy-MM-dd");
    },
    goToIndex() {
      this.$router.push({
        path: "/index",
      });
    },
  },
};
</script>
  
<style scoped>
.share_code {
  /* visibility: hidden; */
  position: absolute;
  z-index: 1001;
  top: -88px;
  right: -35px;
  height: 85px;
  width: 240px;
  line-height: 20px;
  padding: 10px 10px 10px 80px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px #ddd;
  font-size: 13px;
  color: #262626;
  transition: visibility 0.2s;
  text-align: center;
}
.qrcode {
  position: relative;
  left: -74px;
  top: -65px;
  width: 75px;
}
.news_content >>> img {
  max-width: 100% !important;
  height: auto;
}
</style>