<template>
	<div id="top">
	  <!-- <banner :bannerUrl="bannerUrl"></banner> -->
	  <section class="hero-area">
  <div class="hero-slider">
	  callforpaperBg.jpg
	  <div class="breadcrumbs overlay" :style="{backgroundImage: 'url(' + require('../assets/images/banner01.jpg') + ')' }">
  <div class="container">
  <div class="row align-items-center">
  <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
  <div class="breadcrumbs-content">
  <h1 class="page-title"> {{ $t("banners.concern.h1Content") }}</h1>
  <p>{{ $t("banners.concern.content") }}</p>
  </div>
  <ul class="breadcrumb-nav">
  <li><a style="cursor:pointer" @click="goToIndex()">{{ $t("banners.concern.button[0]") }}</a></li>
  <li>{{ $t("banners.concern.button[1]") }}</li>
  </ul>
  </div>
  </div>
  </div>
  </div>
  </div>  
  </section>
  
  <section class="section blog-single">
  <div class="container">
  <div class="row">
  <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
  <div class="single-inner">
  
  <div class="post-details">
  <div class="detail-inner">
  
  <ul class="custom-flex post-meta">
  <li>
  <!-- <a href="javascript:void(0)">
  <i class="lni lni-calendar"></i>
  2024年07月24日
  </a> -->
  </li>
  </ul>
  <div >
	<p style="text-indent:2em;line-height: 24px;">
		<span style="font-size:14px;display: unset;">
			{{ $t("aboutPage.desc") }}
		</span>
	</p>
	<p style="font-size:14px;text-align: center;line-height: 24px;">
		<span>
			{{ $t("aboutPage.companyName[0]") }}<br>
			{{ $t("aboutPage.companyName[1]") }}<br>
			{{ $t("aboutPage.companyName[2]") }}<br>
			{{ $t("aboutPage.companyName[3]") }}<br>
			{{ $t("aboutPage.companyName[4]") }}<br>
			{{ $t("aboutPage.companyName[5]") }}<br>
			{{ $t("aboutPage.companyName[6]") }}<br>
			{{ $t("aboutPage.companyName[7]") }}<br>
			{{ $t("aboutPage.companyName[8]") }}<br>
			{{ $t("aboutPage.companyName[9]") }}<br>
			{{ $t("aboutPage.companyName[10]") }}<br>
			{{ $t("aboutPage.companyName[11]") }}<br>
			{{ $t("aboutPage.companyName[12]") }}<br>
			{{ $t("aboutPage.companyName[13]") }}<br>
		</span>
	</p>
	<br>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce1.title1") }}</strong>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce1.title2") }}</strong>
	</p>
	<p style="text-indent:2em;">
		{{ $t("aboutPage.introduce1.content[0]") }}<br>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce1.content[1]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce1.content[2]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce1.content[3]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce1.content[4]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce1.content[5]") }}
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce2.title2") }}</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce2.content[0]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce2.content[1]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce2.content[2]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce2.content[3]") }}
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce3.title1") }}</strong>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce3.title2") }}</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce3.content[0]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce3.content[1]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce3.content[2]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce3.content[3]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce3.content[4]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce3.content[5]") }}
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce4.title1") }}</strong>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce4.title2") }}</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce4.content[0]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce4.content[1]") }}
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce5.title2") }}</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce5.content[0]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce5.content[1]") }}
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce6.title2") }}</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce6.content[0]") }}
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce7.title2") }}</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce7.content[0]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce7.content[1]") }}
		</span>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce8.title1") }}</strong>
	</p>
	<p style="font-size: 24px;text-align: center;color: black;">
		<strong>{{ $t("aboutPage.introduce8.title2") }}</strong>
	</p>
	<p >
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce8.content[0]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce8.content[1]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce8.content[2]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce8.content[3]") }}
		</span>
		<span style="display: inline-block;text-indent: 2em;">
			{{ $t("aboutPage.introduce8.content[4]") }}
		</span>
	</p>
  </div>
 	
			
  <div class="post-tags-media">
  
  <div class="post-social-media">

  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </section>
  
  
  
   
  
  <a href="javascript:void(0)" class="scroll-top btn-hover" @click="scrollToAnchor('top')">
  <i class="lni lni-chevron-up"></i>
  </a>
	</div>
  </template>
	
  <script>
   
	
	import indexnav from "@/components/nav/indexnav.vue"
	import banner from "@/components/header/banner.vue"
  
  
  export default {
	 name:"IndexPage",
	 components:{
	  indexnav,
	  banner
	 },
	 data(){
	  return{
		  bannerUrl:["BANNER.png"]
	  }
	 },
	 mounted(){
		//  TouchSlide({ 
	  // 		slideCell:"#news_slide",
	  // 		mainCell:".bd ul",
	  // 		effect:"leftLoop",
	  // 		autoPlay:true//自动播放
	  //  });
	  const slide = document.querySelector(".hero-slider");
	  console.log(slide)
		 tns({
			  container: '.hero-slider',
			  items: 1,
			  slideBy: 'page',
			  autoplay: false,
			  mouseDrag: true,
			  gutter: 0,
			  nav: true,
			  controls: false,
			  controlsText: ['<i class="lni lni-arrow-left"></i>', '<i class="lni lni-arrow-right"></i>'],
		  });
	  
  
	   document.addEventListener('click', evnt => {
		  let anchor = evnt.target.closest('a[href^="#"]')
		  if (anchor) {
			  evnt.preventDefault()
			  let value = anchor.getAttribute('set_data');
			  if(value){
				 this.newsDetail(value)
			  }
			 
		  }
		  })
   },
   methods: {
	  downLoadFile(fileName){
		  const fileUrl =  '/img/'+fileName;
		  const link = document.createElement('a');
		  link.href = fileUrl;
		  link.setAttribute('download',fileName);
		  link.click();
	  },
		 goToIndex(){
		 this.$router.push({
			path:"/index"
		  })
	  },
	  scrollToAnchor(id){
		  var anchor = document.getElementById(id);
		  anchor.scrollIntoView();
	  },
	   newsDetail(index){
		  console.log(11111)
		  this.$router.push({
			path:"/newsDetail/"+index
		  })
	   },
	   caseDetail(type){
	   this.$router.push({
			path:"/caseDetail/"+type
		})
	  }
   }
	  
  }
  </script>
	
  <style>
  
	  
	  
  
  </style>