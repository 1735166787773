<template>
  <div id="top">
    <!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
      <div class="hero-slider">
        callforpaperBg.jpg
        <div
          class="breadcrumbs overlay"
          :style="{
            backgroundImage:
              'url(' + require('../assets/images/banner01.jpg') + ')',
          }"
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">
                    {{ $t("banners.paper.h1Content") }}
                  </h1>
                  <p>{{ $t("banners.paper.content") }}</p>
                </div>
                <ul class="breadcrumb-nav">
                  <li>
                    <a style="cursor: pointer" @click="goToIndex()">{{
                      $t("banners.paper.button[0]")
                    }}</a>
                  </li>
                  <li>{{ $t("banners.paper.button[1]") }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section blog-single">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
            <div class="single-inner">
              <div class="post-details">
                <div class="detail-inner">
                  <ul class="custom-flex post-meta">
                    <li>
                      <a href="javascript:void(0)">
                        <i class="lni lni-calendar"></i>
                        {{ $t("callPage.date") }}
                      </a>
                    </li>
                  </ul>
                  <h2 class="post-title">
                    <a href="javascript:void(0)"> {{ $t("callPage.title1") }}</a>
                  </h2>
                  <p>
                   {{ $t("callPage.content1[0]") }}

                    <br /> {{ $t("callPage.content1[1]") }}

                    <br /> {{ $t("callPage.content1[2]") }}
                    <br /> {{ $t("callPage.content1[3]") }}
                    <br /> {{ $t("callPage.content1[4]") }}

                    <br /> {{ $t("callPage.content1[5]") }}
                  </p>
                  <h3>{{ $t("callPage.title2") }}</h3>
                  <ul class="list">
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>{{ $t("callPage.content2[0]") }}
                    </li>
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>{{ $t("callPage.content2[1]") }}
                    </li>

                    <li>{{ $t("callPage.content2[2]") }}</li>

                    <li>
                      <i class="lni lni-chevron-right-circle"></i> {{ $t("callPage.content2[3]") }}
                    </li>
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>{{ $t("callPage.content2[4]") }}
                    </li>
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>{{ $t("callPage.content2[5]") }}
                    </li>
                    <li>
                      <i class="lni lni-chevron-right-circle"></i>{{ $t("callPage.content2[6]") }}
                    </li>
                  </ul>
                  <p>
                    {{ $t("callPage.content2[7]") }}
                  </p>

                  <!-- <h3>【注册与优惠】</h3>
<p>会务注册费1500元/人
    <br>投稿人员可抵扣会务费，具体凭证请联系会务组
</p> -->
                  <h3>{{ $t("callPage.title3") }}</h3>
                  <p>{{ $t("callPage.content3[0]") }}</p>
                  <!-- <h3>【论坛亮点】</h3>
<p><i class="lni lni-chevron-right-circle"></i> 行业精英汇聚，共促储能技术创新
    <br><i class="lni lni-chevron-right-circle"></i> 加强产学研合作，推进智慧电站建设
</p> -->
                  <!-- <p>我们诚挚邀请您参与此次论坛，共同探讨储能与智慧电站的未来，期待您的精彩论文和宝贵意见！<br> 会议官网即将公布，敬请期待！ </p> -->

                  <!-- <div class="post-tags-media">
<div class="post-tags popular-tag-widget mb-xl-40">
    <p class="share-title"><a  style="color: red;border-bottom: 1px solid;cursor: pointer;" @click="downLoadFile('2024第二届储能与智慧电站关键技术论坛-预通知及征稿函.pdf')">附件： 2024第二届储能与智慧电站关键技术论坛-预通知及征稿函</a></p>

</div>
<div class="post-social-media">


</div>
</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <a
      href="javascript:void(0)"
      class="scroll-top btn-hover"
      @click="scrollToAnchor('top')"
    >
      <i class="lni lni-chevron-up"></i>
    </a>
  </div>
</template>
  
<script>
import indexnav from "@/components/nav/indexnav.vue";
import banner from "@/components/header/banner.vue";

export default {
  name: "IndexPage",
  components: {
    indexnav,
    banner,
  },
  data() {
    return {
      bannerUrl: ["BANNER.png"],
    };
  },
  mounted() {
    //  TouchSlide({
    // 		slideCell:"#news_slide",
    // 		mainCell:".bd ul",
    // 		effect:"leftLoop",
    // 		autoPlay:true//自动播放
    //  });
    const slide = document.querySelector(".hero-slider");
    console.log(slide);
    tns({
      container: ".hero-slider",
      items: 1,
      slideBy: "page",
      autoplay: false,
      mouseDrag: true,
      gutter: 0,
      nav: true,
      controls: false,
      controlsText: [
        '<i class="lni lni-arrow-left"></i>',
        '<i class="lni lni-arrow-right"></i>',
      ],
    });

    document.addEventListener("click", (evnt) => {
      let anchor = evnt.target.closest('a[href^="#"]');
      if (anchor) {
        evnt.preventDefault();
        let value = anchor.getAttribute("set_data");
        if (value) {
          this.newsDetail(value);
        }
      }
    });
  },
  methods: {
    downLoadFile(fileName) {
      const fileUrl = "/img/" + fileName;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileName);
      link.click();
    },
    goToIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    scrollToAnchor(id) {
      var anchor = document.getElementById(id);
      anchor.scrollIntoView();
    },
    newsDetail(index) {
      console.log(11111);
      this.$router.push({
        path: "/newsDetail/" + index,
      });
    },
    caseDetail(type) {
      this.$router.push({
        path: "/caseDetail/" + type,
      });
    },
  },
};
</script>
  
<style>
</style>