<template>
    <div>
    <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle" :bannerTitle="bannerTitle" :subtitle="true"></banner>

    <div class="com_zi clearfix">
		<div class="left_zi">
			<div class="top_com">
				<span>工程案例</span>
			</div>
			<ul class="nav_left">
				<li :class="{'active':type == 1}">
					<a @click="checkType(1)">虾稻共作</a>
				</li>
				<li :class="{'active':type == 2}">
					<a @click="checkType(2)">高位池养殖</a>
				</li>
				<li :class="{'active':type == 3}">
					<a @click="checkType(3)">河蟹精养</a>
				</li>
			</ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a @click="checkType(1)">工程案例</a> > <a>{{ productName}}</a> </span>
			</div>
      <ul class="case_zi_ul clearfix">
				<li  v-if="type == 1">
					<a  class="pic">
						<!-- <img src="../assets/images/2019011515285090ae79.jpg" alt="" style="display: block;margin: auto"/> -->
						<!-- <img src="../assets/images/2019011515393242d7bd.jpg" alt="" style="display: block;margin: auto"/> -->
					</a>
					<div class="text">
						<a @click="caseDetail(1)" >查看详情>></a>
					</div>
				</li>
        <li v-if="type == 2">
					<a  class="pic">
						  <!-- <img src="../assets/images/20240223092855.jpg" alt="" style="display: block;margin: auto"/> -->
							<!-- <img src="../assets/images/2019011515414162e88b.jpg" alt="" style="display: block;margin: auto"/> -->
					</a>
					<div class="text">
						<a @click="caseDetail(2)">查看详情>></a>
					</div>
				</li>
        <li v-if="type == 3">
					  <a class="pic">
						  <!-- <img src="../assets/images/20190115153637b64294.jpg" alt="" style="display: block;margin: auto"/> -->
						  <!-- <img src="../assets/images/2019011515430696872f.jpg" alt="" style="display: block;margin: auto"/> -->
					  </a>
					  <div class="text">
						  <a  @click="caseDetail(3)">查看详情>></a>
					  </div>
				</li>
			</ul>
      
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"

export default {
    name:"IndexPage",
  components:{
    
    Banner,
    MyNav,
   
    
  },
  beforeRouteUpdate(to,from,next){
    console.log("case111111")
    if(to.params.type != this.type){
      this.type = to.params.type;
    }
    next()
  },

  data(){
    return {
      bannerUrl: ["201901161221277fdcad.jpg"],
      type: 1,
      productName:'虾稻共作',
      divStyle: '',
      imgStyle: '',
      bannerTitle: ["工程案例","CASE"]
    }
  },
  watch: {
    type(item1,item2){
      console.log("++++++++++++")
      if(item1 != item2){
        if(this.type == 1){
          this.productName = '虾稻共作'
        }else if(this.type == 2){
          this.productName = '高位池养殖'
        }else if(this.type == 3){
          this.productName = '河蟹精养'
        }
      }
    }
  },
  created(){
    if(this.$route.params){
      this.type = this.$route.params.type;
      if(this.type == 1){
          this.productName = '虾稻共作'
      }else if(this.type == 2){
          this.productName = '高位池养殖'
      }else if(this.type == 3){
          this.productName = '河蟹精养'
      }
    }
  },
  methods: {
    checkType(type){
      this.type = type;
    },
    caseDetail(type){
     this.$router.push({
          path:"/caseDetail/"+type
      })
    }
  }
}
</script>
  
<style>

    
    

</style>