var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('banner',{attrs:{"bannerUrl":_vm.bannerUrl,"divStyle":_vm.divStyle,"imgStyle":_vm.imgStyle,"bannerTitle":_vm.bannerTitle,"subtitle":true}}),_c('div',{staticClass:"com_zi clearfix"},[_vm._m(0),_c('div',{staticClass:"right_zi"},[_c('div',{staticClass:"bar clearfix"},[_c('span',{staticClass:"b_tt"},[_vm._v(_vm._s(_vm.productName))]),_c('span',{staticClass:"curbar"},[_c('i',[_vm._v("您当前的位置：")]),_c('a',[_vm._v("数据中心")]),_vm._v(" > "),_c('a',[_vm._v(_vm._s(_vm.productName))])])]),_c('ul',{staticClass:"news_zi_ul clearfix"},[_c('li',[_c('a',{staticClass:"clearfix",staticStyle:{"height":"150px","margin-bottom":"10px"},on:{"click":function($event){return _vm.dataDetail(1)}}},[_c('span',{staticClass:"left",staticStyle:{"width":"30%"}}),_vm._m(1),_vm._m(2)])]),_c('li',[_c('a',{staticClass:"clearfix",staticStyle:{"height":"150px","margin-bottom":"10px"},on:{"click":function($event){return _vm.dataDetail(2)}}},[_c('span',{staticClass:"left",staticStyle:{"width":"30%"}}),_vm._m(3),_vm._m(4)])]),_c('li',[_c('a',{staticClass:"clearfix",staticStyle:{"height":"150px","margin-bottom":"10px"},on:{"click":function($event){return _vm.dataDetail(3)}}},[_c('span',{staticClass:"left",staticStyle:{"width":"30%"}}),_vm._m(5),_vm._m(6)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_zi"},[_c('div',{staticClass:"top_com"},[_c('span',[_vm._v("数据中心")])]),_c('ul',{staticClass:"nav_left"},[_c('li',{staticClass:"active"},[_c('a',[_vm._v("行业数据")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"left",staticStyle:{"vertical-align":"middle","line-height":"20px","text-align":"left","width":"35%","margin-left":"15px"}},[_c('h4',[_vm._v("蓝藻频繁爆发？多数和这个指标有关！")]),_c('span',{staticStyle:{"padding-left":"25px"}},[_vm._v(" 蓝藻，水产养殖池塘中恶魔般的存在。危害大、易爆发、不易处理，让人焦头烂额，甚至损失惨重。...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"right"},[_c('time',[_vm._v("01-10")]),_c('i')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"left",staticStyle:{"vertical-align":"middle","line-height":"20px","text-align":"left","width":"35%","margin-left":"15px"}},[_c('h4',[_vm._v("南美白对虾标苗塘pH偏高处理方案")]),_c('span',{staticStyle:{"padding-left":"25px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"right"},[_c('time',[_vm._v("01-10")]),_c('i')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"left",staticStyle:{"vertical-align":"middle","line-height":"20px","text-align":"left","width":"35%","margin-left":"15px"}},[_c('h4',[_vm._v("水瘦导致加州鲈养殖池溏溶氧偏低处理")]),_c('span',{staticStyle:{"padding-left":"25px"}},[_vm._v(" .")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"right"},[_c('time',[_vm._v("01-10")]),_c('i')])
}]

export { render, staticRenderFns }