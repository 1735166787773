<template>
    <div>
    <banner :bannerUrl="bannerUrl" :divStyle="divStyle" :imgStyle="imgStyle" :bannerTitle="bannerTitle" :subtitle="true"></banner>
    <div class="com_zi clearfix">
		<div class="left_zi">
			<div class="top_com">
				<span>数据中心</span>
			</div>
			<ul class="nav_left">
				<li class="active">
					<a>行业数据</a>
				</li>
			</ul>
		</div>
		<div class="right_zi">
			<div class="bar clearfix">
				<span class="b_tt">{{productName}}</span>
				<span class="curbar"><i>您当前的位置：</i><a >数据中心</a> > <a >{{ productName}}</a> </span>
			</div>
     
			<ul class="news_zi_ul clearfix">
				<li>
					<!-- vertical-align: middle; -->
					<a @click="dataDetail(1)" class="clearfix" style="height: 150px;margin-bottom: 10px; ">
						<span class="left" style="width: 30%;">
							<!-- <img style="float:left;display: block;" width="200px" height="150px" src="../assets/images/2019011614152931c68a.jpg"  /> -->
						</span>
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>蓝藻频繁爆发？多数和这个指标有关！</h4>
							<span style="padding-left: 25px;"> 蓝藻，水产养殖池塘中恶魔般的存在。危害大、易爆发、不易处理，让人焦头烂额，甚至损失惨重。...</span>
						</span>
						<span class="right">
							<time>01-10</time>
							<!-- <span>2016</span> -->
							<i></i>
						</span>
					</a>
				</li>
				<li>
					<!-- vertical-align: middle; -->
					<a @click="dataDetail(2)" class="clearfix" style="height: 150px;margin-bottom: 10px; ">
						<span class="left" style="width: 30%;">
							<!-- <img style="float:left;display: block;" width="200px" height="150px" src="../assets/images/20190116140140984798.jpg"  /> -->
						</span>
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>南美白对虾标苗塘pH偏高处理方案</h4>
							<span style="padding-left: 25px;"> </span>
						</span>
						<span class="right">
							<time>01-10</time>
							<!-- <span>2016</span> -->
							<i></i>
						</span>
					</a>
				</li>
				<li>
					<!-- vertical-align: middle; -->
					<a @click="dataDetail(3)" class="clearfix" style="height: 150px;margin-bottom: 10px; ">
						<span class="left" style="width: 30%;">
							<!-- <img style="float:left;display: block;" width="200px" height="150px" src="../assets/images/2019011610521460b04a.jpg"  /> -->
						</span>
						<span class="left" style="vertical-align: middle;line-height: 20px;text-align:left;width: 35%;margin-left: 15px">
							<h4>水瘦导致加州鲈养殖池溏溶氧偏低处理</h4>
							<span style="padding-left: 25px;"> .</span>
						</span>
						<span class="right">
							<time>01-10</time>
							<!-- <span>2016</span> -->
							<i></i>
						</span>
					</a>
				</li>
			</ul>
      
		</div>
	</div>
    
    </div>
</template>
  
<script>

import Banner from "@/components/header/banner.vue"
import MyNav from "@/components/nav/nav.vue"

export default {
    name:"IndexPage",
  components:{
    
    Banner,
    MyNav,
   
    
  },
  beforeRouteUpdate(to,from,next){
    console.log(111111)
     this.detail = false;
    if(to.params.type != this.type){
      this.type = to.params.type;
    }
    next()
  },

  data(){
    return {
      // bannerUrl: ["20240220143932.png"],
      bannerUrl: ["2018122313504180a5df.jpg"],
      productName:'行业数据',
      divStyle: '',
      imgStyle: '',
      bannerTitle: ["数据中心","DATA"]
    }
  },

  created(){
    if(this.$route.params){
      this.type = this.$route.params.type;
    }
  },
  methods: {
    checkType(type){
      this.type = type;
    },
    dataDetail(index){
     this.$router.push({
          path:"/dataDetail/"+index
      })
    }
  }
}
</script>
  
<style>

    
    

</style>