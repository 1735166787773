import { Button } from "element-ui";

const en = {
    logo: '../../assets/images/logo1-01.svg',
    classify:{ 
        title: [{title: 'Home',href:'/index',child: []},
                {title: 'About',href:'/about',child: [{title: 'Review of Previous Sessions',href:'/aboutHisNews'},{title: 'Latest Trends',href:'/aboutHis/news'}]} ,
                {title: 'Committee',href:'/committee',child: []},
                {title: 'Paper',href:'/call',child: [{title: 'Submission of Thesis',href:''}]} ,
                {title: 'Registration',href:'/metting',child: []} ,
                {title: 'Speakers',href:'/speaker',child: []} ,
                {title: 'Contact Us',href:'/contentus',child: []} 

        ]
    },
    banners: {
        home: {
            title: "Notice about Meeting",
            h1Content: 'The 2nd 2024 Forum on Key Technologies of Energy Storage and Intelligent Power Stations',
            content: 'The 2nd 2024 Forum on Key Technologies of Energy Storage and Intelligent Power Stations, with the theme of "Intelligence Empowerment Drives the Future", will be held in the Radisson Shuiyou Hotel Tianjin Heping from October 18th to 20th, 2024.',
            button: ['more','Registration']
        },
        concern: {
            title: "",
            h1Content: 'About',
            content: 'We are honored to announce that the 2nd 2024 Forum on Key Technologies of Energy Storage and Intelligent Power Stations is about to be held grandly! We invite you to jointly explore a new chapter in the development of energy storage!',
            button: ['Home','About']
        },
        latest: {
            title: "",
            h1Content: 'Latest Trends',
            content: 'Welcome to follow the latest news of the Forum on Key Technologies of Energy Storage and Intelligent Power Station',
            button: ['Home','Latest Trends']
        },
        committee: {
            title: "",
            h1Content: 'Organizing committee',
            content: 'We are honored to announce that the 2nd 2024 Forum on Key Technologies of Energy Storage and Intelligent Power Stations will be held in a grand manner! We invite you to explore a new chapter in energy storage development together!',
            button: ['Home','Organizing committee']
        },
        paper: {
            title: "",
            h1Content: 'Paper',
            content: 'We are honored to announce that the 2nd 2024 Forum on Key Technologies of Energy Storage and Intelligent Power Stations will be held in a grand manner! We invite you to explore a new chapter in energy storage development together!',
            button: ['Home','Paper'] 
        },
        registration: {
            title: "",
            h1Content: 'Notice',
            content: 'The 3nd 2025 Forum on Key Technologies of Energy Storage and Intelligent Power Stations, with the theme of "Intelligence Empowerment Drives the Future", will be held in the Radisson Shuiyou Hotel Tianjin Heping from October 18th to 20th, 2025',
            button: ['Home','Registration'] 
        },
        guest: {
            title: "",
            h1Content: 'Speakers',
            content: 'Introduction of Guest Speakers in Previous Forums on key Technologies of Energy Storage and Intelligent Power Stations',
            button: ['Home','Guest Speakers'] 
        },
        contactUs: {
            title: "",
            h1Content: 'Contact Us',
            content: 'We are honored to announce that the 2nd 2024 Forum on Key Technologies of Energy Storage and Intelligent Power Stations will be held in a grand manner! We invite you to explore a new chapter in energy storage development together!',
            button: ['Home','Contact Us'] 
        }

    },
    indexPage: {
        important1 : {
            title: 'Meeting Notice',
            content: 'The 2nd Forum on Key Technologies of Energy Storage and Smart Power Stations in 2024 will beheld in Tianjin, China from October 18th to October 20th, 2024.',
            content1: '',
            button: 'Learn More'
        },
        important2 : {
            title: 'Call for Papers for the Conference',
            content: 'The Position of Energy Storage in Multiple Scenarios',
            content1: 'and Key Technologies of Smart Power Stations and Green Power Stations.',
            button: 'Learn More'
        },
        important3 : {
            title: 'Important Dates',
            content: 'Registration Deadline for the Conference: October 14th, 2024.',
            content1: 'Conference Dates: From October 18th to October 20th, 2024.',
            button: 'Learn More'
        },
        button: 'Review of Past Sessions',
        button1: 'Learn More'
    },
    aboutPage: {
        desc:'The 2nd Forum on Key Technologies for Energy Storage and Smart Power Stations in 2024, themed "Empowering the Future with Intelligence," will be held in Tianjin, China, from October 18th to October 20th, 2024. This forum aims to establish a professional and innovative academic exchange platform for experts, scholars, and industry professionals in the fields of energy storage technology, smart grids, and smart power stations in China. The conference will invite renowned scholars and senior experts from the frontline of the industry, including Professor Wang Chengshan\'s team from the Chinese Academy of Engineering and Tianjin University, Tsinghua University, Beihang University, China Datang Corporation, Shanghai Survey and Design Institute of China Three Gorges Corporation, and China Yangtze Power Co., Ltd., to engage in discussions on key technologies such as "energy storage equipment, hydropower equipment, smart power stations, digital twins, knowledge engineering, intelligent design, intelligent operation and maintenance, efficiency enhancement and capacity expansion." These experts will fully showcase and share innovative ideas and research findings.',
        companyName: ['Organized by','National Industry-Education Platform for Energy Storage (Tianjin University)',"Tianjin Ren'ai College",'Hosted by','Tianjin Tianfa General Plant Electromechanical Equipment Co., Ltd.',"Tianjin Ren'ai College",'Supported by'
			,'Relay Protection and Excitation Committee of the China Society for Hydropower Engineering','Tianjin Mechanical Engineering Society','Tianjin Electric Power Engineering Association','Hebei Society for Hydropower Engineering',"Green Hydropower Research Institute, Tianjin Ren'ai College"
			,'Supporting Media','Journal of Mechanical Design'],
        introduce1: {
                title1: 'Organized by',
                title2: 'National Industry-Education Platform for Energy Storage (Tianjin University)',
                content: [
                    'Energy Storage is an emerging industry and national strategy. As one of the first three universities approved to establish the National Industry-Education Platform for Energy Storage, Tianjin University is implementing the guidelines set forth by the Ministry of Education and the National Development and Reform Commission. The university aligns with the national energy strategy, targets advanced international standards, and leverages its strengths in the development of new engineering disciplines. By integrating high-quality resources from academia and industry, Tianjin University strives to advance talent cultivation and technological innovation in the energy storage field. The goal is to build a comprehensive platform for talent development, discipline construction, and technological breakthroughs in energy storage technology, referred to as the "National Platform for Energy Storage". This platform aims to train future leading engineers and scientists who can drive technological progress and industrial development in energy storage.',
                    "The National Platform for Energy Storage of Tianjin University was officially established in June 2022, with a total project investment of 416.55 million RMB. The platform integrates strengths from the fields of electrochemistry, new materials, hydrogen energy, smart grids, and the economics and policies of energy storage. The initial phase involves the establishment of five research centers: Electrochemical Energy Storage, Fuel Energy Storage and Applications, Energy Storage Equipment and Systems, Energy Storage Safety and Operations, and Energy Storage Economics and Policies. The platform's core functional area is located in Building 58 of the Peiyangyuan Campus, supported by upgraded national and provincial key laboratories from the School of Chemical Engineering, the School of Materials Science, the School of Mechanical Engineering, and the School of Automation.",
                    'In 2021, the National Platform for Energy Storage of Tianjin University received approval to establish the undergraduate program in Energy Storage Science and Engineering, enrolling its first cohort of students. In 2022, it was authorized to confer a master’s degree in the interdisciplinary field of Energy Storage Science and Engineering and began focusing on the development of a doctoral degree program. The platform, operating under the Future Technologies Institute, has established an effective management mechanism and a comprehensive curriculum system, strongly supporting the cultivation of high-level talent. In September 2022, Tianjin University was selected as one of the 10 universities nationwide to implement a national special program for the cultivation of urgently needed high-level talent in energy storage technology. Furthermore, "Energy Storage Technology" became one of the six university-level interdisciplinary sub-centers under Tianjin University\'s inaugural batch of such initiatives. Breaking through the limitations of traditional disciplines, colleges, and industries, the platform has assembled a high-caliber, interdisciplinary, and multifaceted faculty team. This team is led by three academicians—Jin Donghan, Wang Chengshan, and Zhao Tianshou—along with over 20 nationally recognized leading experts and more than 40 young national-level talents, covering all aspects and the entire value chain of energy storage.',
                    'Focusing on the principles of "dual-integrity and single-degree" (professional depth, interdisciplinary breadth, and education quality), the platform advances reforms in professional education, curricula, teaching materials, and methodologies. It aims to establish a Tianjin University-specific talent cultivation system for energy storage, accelerating the training of high-level professionals to address the most pressing and advanced needs in the energy storage field. Serving as the university\'s "hub for interdisciplinary innovation", the platform implements an industry-education integrated collaborative education program, adopting a "1+N+X" model: one core discipline (energy storage), supported by the university’s strengths in various other disciplines (N), in collaboration with leading enterprises in the energy storage sector (X). This model emphasizes early, frequent, and high-level practical opportunities for students, exploring an education model tailored to the growth of outstanding engineers. The platform has signed talent co-cultivation agreements with companies such as China Energy, China Energy Engineering Corporation Limited, Sinopec, China Southern Power Grid, BYD, Dongfang Electric Corporatiob, Shanghai Hydrogen Propulsion Technology Co., Ltd, YC Simlan, Trina Solar, and Goldwind. It supports specialized doctoral students\' participation in corporate R&D projects and encourages young technical experts from enterprises to pursue targeted doctoral programs.',
                    'By aligning with national energy strategies and adhering to the principles of "leveraging unique advantages, serving regional development, promoting collaboration across industries, and enabling open sharing," the National Platform for Energy Storage integrates regional characteristics with educational and industrial resources. It focuses on cultivating outstanding engineering talent in energy storage and enhancing research capabilities in key frontier areas such as electrochemical energy storage, fuel energy storage, and energy storage systems and equipment. The platform aims to address "bottleneck" issues in the energy storage sector, solve critical technical challenges faced by enterprises, and seamlessly link the talent, innovation, industrial, and supply chains. It endeavors to create a three-pronged approach to discipline construction, technological breakthroughs, and talent development as part of the National Industry-Education Platform for Energy Storage.',
                    '2023 marks the starting point for the Party\'s second centenary goal and serves as a pivotal year for Tianjin University to accelerate high-quality development under the "14th Five-Year Plan." It is also a critical year for the strategic planning and development of the National Platform for Energy Storage. Guided by Xi Jinping Thought on Socialism with Chinese Characteristics for a New Era and the spirit of the 20th Party Congress, the platform will intensify efforts in talent cultivation, discipline development, and technological innovation. It aspires to build a world-class National Industry-Education Platform for Energy Storage, providing strong talent and technological support for the advancement of China\'s energy storage industry and enhancing its international competitiveness.'
                ]
        },
        introduce2: {
            title1: '',
            title2: 'Tianjin Ren\'ai College',
            content: [
                'The predecessor of Tianjin Ren\'ai College was Tianjin University Ren\'ai College, which was established in 2006 with the approval of the Ministry of Education. It was a full-time, undergraduate institution jointly run by Tianjin University and Tianjin Ren\'ai Group. In 2021, it was officially authorized by the Ministry of Education to transition into Tianjin Ren\'ai College.',
                'In recent years, the college has consistently ranked first in the annual inspection by the Tianjin Municipal Education Commission and has been recognized as "Excellent" for three consecutive years in the employment quality assessment of universities in Tianjin. It has also ranked fifth in the annual alumni association rankings of private universities in China for two consecutive years.',
                'The campus spans more than 1,000 acres, with a building area of approximately 380,000 square meters. The college currently enrolls nearly 17,000 students, and its staff exceeds 1,000. Focusing on engineering education, Tianjin Ren\'ai College offers 36 undergraduate programs. The college is committed to cultivating applied talents and places a high emphasis on school-enterprise cooperation and the establishment of industry-university-research bases. It has made significant achievements in integrating education with industry and scientific research. The college has established modern industry colleges in partnership with companies like iFlytek Co., Ltd. and Chinasoft International Education Technology Group, and collaborates with more than 70 companies, including Tiandi, to build industry-university-research bases and off-campus practice bases. These initiatives help to continuously supply talent to enterprises. Tianjin Ren\'ai College has also seen a significant increase in research funding, breaking through RMB 100 million in research funding for three consecutive years. It has a strong team of business liaisons dedicated to solving "bottleneck" issues for companies. The college\'s Mechanical Engineering discipline, led by Professor Zhang Junhong, was awarded the First Prize of the Mechanical Industry Science and Technology Award, the highest recognition for technological achievements in the mechanical industry. As the chairman of the Tianjin Technology Manager Development Promotion Association, the college has made significant breakthroughs in research achievements.',
                'After the transformation, the college, Ren\'ai Group, and Tianjin University signed a comprehensive talent development agreement. Ren\'ai Group allocates RMB 30 million annually to Tianjin University to support the overall development and construction of Tianjin Ren\'ai College. In recent years, several outstanding young teachers have been selected to pursue doctoral studies at Tianjin University, and 200 outstanding undergraduate students have been sent to Tianjin University for one-year exchange programs in related fields. This translation maintains the key details and context of the original text, adapting it for an English-speaking academic audience.'
                ]
        },
        introduce3: {
            title1: 'Hosted by',
            title2: 'Tianjin Tianfa General Plant Electromechanical Equipment Co., Ltd.',
            content: [
                'Founded in 1938 and expanded in 1958, Tianjin Power Equipment Manufactory General Factory is a state-designated major enterprise for the production of complete hydropower equipment and large pump units. Tianjin Power Equipment Manufactory General Factory has won numerous national, ministerial, and Tianjin municipal science and technology progress awards, as well as awards for excellent new products. It has made outstanding contributions to the development of China’s water conservancy and electric power industries.',
                'The plant has developed and spun off several specialized hydropower equipment manufacturing companies, including Tianjin Tianfa General Plant Electromechanical Equipment Co., Ltd., Tianfa Heavy Machinery Hydro power, and Tianjin Alstom, as well as other manufacturers involved in water conservancy and hydropower.',
                'Over the past six decades, the Tianfa Group’s hydro power equipment manufacturing enterprises have designed and produced more than 1,500 sets of mixed-flow, through-flow, axial-flow, and pumped storage hydro turbine units. Among them, over 800 sets are through-flow units, making it the world’s largest manufacturer of medium and large through-flow turbine units, and establishing itself as a global hydropower equipment service provider.',
                'Tianjin Tianfa General Plant Electromechanical Equipment Co., Ltd. inherits more than 60 years of hydro power history from the Tianfa Group and continues to advance and enhance Tianfa technology. The company adopts a “4S service, 10-value” full-lifecycle maintenance concept and has adjusted its industrial and project execution models. It implements a 24-hour rapid response mechanism to meet the demands of the market and users.',
                'Tianjin Tianfa General Plant consistently adheres to technological innovation and is now recognized as a National High-Tech Enterprise, a National Technology-Based Small and Medium-Sized Enterprise, and a Tianjin Eagle Enterprise. The company holds multiple patents and proprietary technologies.',
                'It has become a qualified supplier to several major state-owned enterprises in China, including China Guodian Corporation, China Huadian Corporation, China Datang Corporation, China Gezhouba Group Co., LTD., China Huaneng Group Co., LTD., China Power Investment Corporation (CPI), and Northern International Group.By the end of 2020, Tianjin Tianfa General Plant had provided over 300 hydropower stations with services such as major repairs, renovations, efficiency improvements, spare parts, technical support, and complete system optimization under its “4S service” model.'
                ]
        },
        introduce4: {
            title1: 'Supported by',
            title2: 'Introduction to the Relay Protection and Excitation Professional Committee of the China Society for Hydropower Engineering',
            content: [
                'The Relay Protection and Excitation Professional Committee of the China Society for Hydropower Engineering (hereinafter referred to as the Committee) was established on May 18, 2005, with the initial aim of leveraging the expertise of numerous outstanding industry professionals to assist various organizations and enterprises in the hydropower sector in effectively carrying out their work. It aims to provide a platform for information sharing, cooperation, and exchange among hydropower enterprises, societies, and technical professionals. The Committee facilitates timely understanding of the latest technological fronts domestically and internationally among peers, encourages joint exploration of new achievements and advancements in disciplinary development, and promotes the development of China\'s power plant relay protection and safety automatic devices, excitation systems, DC systems, and other related specialties towards safer, smarter, and more efficient directions.',
                'Currently, the Professional Committee directly connects with and serves over 20,000 members, widely covering major industry-academia-research-related institutions in the hydropower sector. It has become an influential social organization in the secondary system field of hydropower. Over a decade of development, the Professional Committee\'s influence has been growing steadily. On January 2, 2016, a new leadership was elected, with the Secretariat established at Xiluodu Hydropower Station (the fourth largest hydropower station in the world). Moving forward while building on past achievements, the Professional Committee focuses on industry hotspots and challenges, actively functioning as a bridge and tie. Through technical exchange seminars, academic annual conferences, and technical training sessions, it actively builds a broad academic exchange platform for all parties involved in industry, academia, research, and application. Leveraging the technical prowess of leading hydropower enterprises such as China Three Gorges Corporation and China Yangtze Power, as well as various member units, the Professional Committee has assembled an expert service team with solid theoretical knowledge and rich practical experience. It has successively conducted academic exchanges, technical training, and various standard revisions and publications in technical fields such as condition-based maintenance of hydropower stations, anti-accident safety measures discussions, relay protection and excitation system technology applications, lean management evaluations for relay protection, and digital transformation. These efforts have played a positive role in promoting the research and application of hydropower automation technology and facilitating innovative development in the industry.'
            ]
        },
        introduce5: {
            title1: '',
            title2: 'Tianjin Society of Mechanical Engineering',
            content: [
                'Founded in 1951, the Tianjin Society of Mechanical Engineering (TSME) is under the jurisdiction of the Tianjin Association for Science and Technology and serves as a local branch of the Chinese Mechanical Engineering Society. It is an academic and nonprofit organization comprised of universities, research institutions, enterprises, and institutions specializing in mechanical engineering, as well as experts, scholars, and technicians engaged in related fields. The business scope of the TSME includes academic exchanges, technical consulting services, education and training, as well as editing and publishing.',
                'The Tianjin Society of Mechanical Engineering boasts a cumulative membership of over 5,000 nationwide, and currently has a total of 14 branches. The Tianjin Society of Mechanical Engineering and its branches lead the industry and drive innovation in various academic fields of mechanical engineering.'
            ]
        },
        introduce6: {
            title1: '',
            title2: 'Introduction to Tianjin Electric Power Engineering Association',
            content: [
                'The Tianjin Electric Power Engineering Association was established on March 21, 2013, and is registered with the Tianjin Social Organization Administration Bureau, under whose business guidance and supervision the Association operates. With the mission of serving electric power engineering enterprises, the Association acts as a bridge and tie between electric power engineering enterprises, social and market demands, and energy regulatory authorities. It provides members with various services such as policy and regulation updates, regulatory dynamics, industry news, association consultations, and special operation certificate training, facilitating enterprise exchanges and showcasing corporate images and styles. The Association always adheres to a service-centered, demand-oriented, and satisfaction-based approach, conscientiously fulfills its responsibilities, and wholeheartedly serves its members, the electric power industry, and assists electric power administrative departments in resolving difficulties.'
            ]
        },
        introduce7: {
            title1: '',
            title2: 'Introduction to the Green Hydropower Research Institute of Tianjin Ren\'ai College',
            content: [
                'The Green Hydropower Research Institute of Tianjin Ren\'ai College focuses on green energy and clean energy equipment as its primary research areas, with research content encompassing theoretical innovations in green energy equipment technology, as well as innovations and entrepreneurial practices in the green energy industry. It has appointed Academician Wang Chengshan from the National Energy Storage Platform of Tianjin University as the Chairman of the Academic Committee, Professor Zhang Junhong as the Chairman of the Expert Committee, Professor Sun Jiang as the Principal Researcher, Ren Chengzu as the Director, Yu Yangyang as the Executive Deputy Director, and He Ying as the Deputy Director.',
                'Actively track international and domestic research trends in relevant fields, carry out projects at various levels and categories, regularly organize academic exchange activities in related fields, disseminate research results through multiple channels, and continuously enhance the school\'s influence.'
            ]
        },
        introduce8: {
            title1: 'Supporting Media',
            title2: 'Journal of Machine Design Press',
            content: [
                'Mechanical Design, founded in 1983 and officially named as such in 1984, is approved and supervised by the China Association for Science and Technology. It is sponsored by the Chinese Mechanical Engineering Society, Tianjin Society of Mechanical Engineering, and other institutions. It is a national comprehensive academic journal in the machinery industry in China, and serves as the official publication of the Mechanical Design Branch of the Chinese Mechanical Engineering Society. It is also a Chinese core journal, included in the Chinese Science Citation Database (CSCD), designated as an important Chinese journal for degree and graduate education, and a source journal for statistics of scientific papers in China. The influence index and impact factor of Mechanical Design magazine have been increasing year by year, ranking among the top in similar scientific and technological journals, and it has won the Tianjin First-Class Journal Award and Tianjin Excellent Journal Award multiple times. In 2020, it was selected for the high-quality scientific and technological journal classification directory in the field of mechanical engineering.',
                'The fifth editorial board of Mechanical Design consists of 94 editorial members, including 1 chairman, 5 vice chairmen, and 11 academicians. The editor-in-chief of Mechanical Design is Academician Tan Jianrong, while the executive editor-in-chief and president is Senior Engineer Wang Yingzheng, with Professor Chen Wenhua and Professor Feng Chunsheng serving as vice presidents.',
                'In 2019, Mechanical Design launched the "Innovative Design" column, and invited contributions from leading academicians and experts in innovative design such as Lu Yongxiang, Pan Yunhe, Xu Zhilei, and Tan Jianrong, which attracted widespread attention in the design academic community.',
                'In 2022, three papers published in Mechanical Design titled "Lean Digital Twin for the Entire Value Chain of Intelligent Manufacturing," "Research on Data-Driven Product Innovative Design," and "New Thinking in Crowd-Wisdom Design" were awarded the "Excellent Paper Award" by the Chinese Mechanical Engineering Society. Among them, Professor Xiao Renbin, a member of the editorial board of Mechanical Design, authored the paper "Research on Data-Driven Product Innovative Design," which was selected as a highly cited paper (High-PCSI paper) in academic essentials.',
                'Mechanical Design ranks among the top in its field in terms of impact factor among similar scientific and technological journals. Its academic influence, impact factor, and citation frequency have been continuously increasing, and it has been selected for the high-quality scientific and technological journal classification directory in the field of mechanical engineering. Focusing on discipline construction, it has consecutively organized academic activities, decision-making consultation projects, science popularization activities, and provided knowledge updating and training services for its members, forming multiple brand activities.'
            ]
        }
    },
    committeePage: {
        organization1: 'Conference Chairperson',
        name1: 'Sun Jiang',
        organization2: 'Technical Solution Committee',
        name2: 'Zhang Junhong, Li Jinwei, Yu Yangyang',
        organization3: 'Organizing Committee',
        name3: 'Ren Chengzu, Guo Wei, Yan Fei, He Ying, Liu Jinjian, Shi Liwen, Jin Yanmei',
        organization4: 'Publishing Chairperson',
        name4: 'Wang Ying',
    },
    callPage: {
        date: 'July 24th,2024',
        title1: 'Call for Papers for the [Second Forum on Key Technologies for Energy Storage and Smart Power Stations]',
        content1: [
            'Attention, experts, scholars, engineers, and researchers in the field of energy storage and smart power stations!',
            'We are honored to announce that the Second Forum on Key Technologies for Energy Storage and Smart Power Stations in 2024 will soon be held in grand style! We invite you to join us in exploring a new chapter in the development of energy storage!',
            'Conference Dates:October 18th-20th,2024',
            'Deadline for Pre-submission Registration: July 30th,2024',
            'Full Manuscript Deadline:September 10th,2024',
            'This forum is jointly hosted by the Tianjin University Energy Storage Center and Tianjin Ren\'ai College, aiming to promote the innovation and development of energy storage technology and smart power stations, and contribute to achieving the national "3060 Dual Carbon Target." We sincerely invite experts from all walks of life to jointly discuss the key technologies of "energy storage equipment, hydropower equipment, smart power stations, digital twins, knowledge engineering, intelligent design, intelligent operation and maintenance, efficiency enhancement and capacity expansion."'
        ],
        title2: 'The themes for paper submissions cover:',
        content2: [
            'The role of energy storage in multiple scenarios',
            'Key Technologies for Smart and Green Power Stations',
            'The content of submissions includes but is not limited to:',
            'Overview of the Energy Storage Field',
            'Specialist Papers',
            'Energy Storage Technology and Case Studies Analysis',
            'Industrial Design Forum and Information',
            'The manuscripts will be reviewed by 2-3 experts to ensure their quality. Accepted papers will be published in the supplement of Mechanical Design, with a page charge of 1000 yuan per page.'
        ],
        title3: '[Contact Information]',
        content3: [
            'Submission Email:icestj@126.com'
        ]
    },
    speakerPage: {
        year24: [
            {
                title: 'Host of the 2024 Conference',
                name: 'Sun Jiang',
                designation: 'Chairman of the Conference, Leading Talent of Tianjin Ren\'ai College, Professor, Doctor, Chairman of Tianfa General Factory, and General Manager of Shenzhen Hengfa Hydropower Company. He is among the first batch of talent projects such as the "Outstanding Entrepreneur Training Project" in Tianjin. As a leading talent of Tianjin Ren\'ai College, he has been awarded honorary titles such as the Tianjin May Day Labor Medal and Outstanding Entrepreneur of the Chinese Machinery Industry. He has presided over 12 national and provincial-level scientific research and innovation projects, published 6 papers indexed by EI or higher, and holds nearly 50 utility model and invention patents.',
                picUrl: require('@/assets/images/metting_user06.png')
            },
            {
                title: 'Keynote Speaker of the 2024 Conference',
                name: 'Guo Li',
                designation: 'Professor and Doctoral Supervisor in the School of Electrical and Information Engineering at Tianjin University. Deputy Director of the Energy Storage Application Center of the National Energy Storage Innovation Platform of Tianjin University, Deputy Director of the National-Local Joint Engineering Research Center for the Development and Application of Microgrid and Intelligent Power Distribution Systems, and Member of the New Energy Grid-Connection Special Committee of the Chinese Society for Electrical Engineering. He has long been engaged in research work focusing on rapid stability control and optimal operation of new energy power generation and power systems.',
                picUrl: require('@/assets/images/guoli.png')
            },
            {
                title: 'Keynote Speaker of the 2024 Conference',
                name: 'Wang Zhengwei',
                designation: 'Head of the Fluid Machinery and Engineering Research Team of Tsinghua University, Tenured Professor, and Doctoral Supervisor. Member of the Academic Committee of the IAHR Hydraulic Machinery and Systems and Chairman of the Asian branch, Deputy Chairperson of the Turbine Professional Committee of the Chinese Society of Power Engineering, Member of the National Technical Committee for Turbine Standardization, Council Member of the Chinese Renewable Energy Society, and Deputy Chairperson of the Marine Energy Professional Committee of the China Renewable Energy Society.',
                picUrl: require('@/assets/images/metting_user2.png')
            },
            {
                title: 'Keynote Speaker of the 2024 Conference',
                name: 'Liu Jihong',
                designation: 'Professor and Doctoral Supervisor at Beihang University. He has successively obtained bachelor\'s, master\'s, and doctor\'s degrees from Huazhong University of Science and Technology, Beijing Institute of Technology, and Tokyo Metropolitan University. The main research directions and characteristics are modern design theories and methods. He is a standing member of the Technical Committee on CAD and Graphics  and a member of the overall expert group of the "Twelfth Five-Year" Manufacturing Informatization Project of the Ministry of Science and Technology of the People\'s Republic of China.',
                picUrl: require('@/assets/images/liujihong.png')
            },
            {
                title: 'Keynote Speaker of the 2024 Conference',
                name: 'Li Yanhao',
                designation: 'Senior Engineer of Shanghai Investigation, Design and Research Institute Co., Ltd. of China Three Gorges Corporation. He is an outstanding young scientific and technological talent of the China Electricity Technology Market Association. He is deeply engaged in the design and scientific research of major domestic and international hydropower projects. He has presided over and participated in the scientific and technological research on hydraulic machinery of Isimba in Uganda, KGG in Nepal, Tamakoshi III in Nepal, Zixia Hydropower Station in Tibet, as well as pumped storage power stations such as Caiziba in Chongqing, Wushan Daxi in Chongqing and Yanglin in Hainan. He has published 7 high-level papers, and won 6 science and technology awards from national and international industry associations and group companies.',
                picUrl: require('@/assets/images/liyanhao.png')
            },
            {
                title: 'Keynote Speaker of the 2024 Conference',
                name: 'Zhou Zhijun',
                designation: 'Principal Researcher and Senior Engineer of the Science and Technology Research Center of China Yangtze Power Co., Ltd. He once served as the General Manager of the Speed Regulation Business Department and the Deputy General Manager of Yangtze Three Gorges Nengshida Co., Ltd. He has been engaged in the research and development of turbine and hydraulic control technologies for a long time. He has presided over and participated in the research and development of many major projects at the national and provincial ministerial levels. He has presided over and completed the research on domestic 700MW- turbine governor equipment. He has won one Second Prize of the National Science and Technology Progress Award, two Ministerial and Provincial-Level Science and Technology Awards, and two Group-Level Science and Technology Awards.',
                picUrl: require('@/assets/images/zhouzhijun.png')
            },
            {
                title: 'Keynote Speaker of the 2024 Conference',
                name: 'Zhang Haiku',
                designation: 'Professorate Senior Engineer in Datang Hydropower Science and Technology Research Institute Co., Ltd., and Doctor of Engineering. He is one of the first batch of outstanding young talents of China Datang Group, a member of the National Technical Committee for Turbine Standardization, a council member of the China Society for Hydropower Engineering, a senior member of the China Electrotechnical Society, a member of the Metal Structure, Mechanical and Electrical Professional Committee of Xizang Autonomous Region Society of Hydropower Engineering, a member of the Sichuan Collaborative Innovation Center for Major Energy and Power Equipment and Technologies, a member of the Youth Editorial Committee of the key magazine of China technology of "Large Electric Machine and Hydraulic Turbine".',
                picUrl: require('@/assets/images/zhanghaiku.png')
            },
            {
                title: 'Keynote Speaker of the 2024 Conference',
                name: 'Li Tian',
                designation: 'Director and General Manager of Tianjin Climate Exchange, MBA of Fudan University. He has worked in banks, insurance companies, and Internet enterprises for many years. During his tenure at Ant Group, as the General Manager of the Online Utility Bill Payment Industry, he led his team to build the largest online payment platform in China. In December 2019, he was appointed as the General Manager of Tianjin Climate Exchange Co., Ltd. Tianjin Climate Exchange is one of the first batch of comprehensive environmental rights and interests trading institutions in China. It promotes the construction of the market system and has gradually become an important market platform serving the government, industries, academia, research institutions and relevant market parties to promote green, low-carbon and high-quality development.',
                picUrl: require('@/assets/images/litian.png')
            },
        ],
        speakerYears: ['2024','2023'],
        year23: [
            {
                title: 'Special Guest of the 2023 Conference',
                name: 'Wang Chengshan',
                designation: 'Academician of the Chinese Academy of Engineering. Currently, he is the Director of the Key Laboratory of the Ministry of Education on Smart Power Grids and the Director of the Microgrid and Intelligent Power Distribution System of the National-Local Joint Engineering Research Center. He is a Changjiang Scholar Distinguished Professor of the Ministry of Education,  and a leading talent of the "National Special Support Plan" Hundred-Thousand-Ten Thousand Project. He also serves as a member of the Science and Technology Committee of the Ministry of Education, and the Editor-in-Chief of “IET Energy Systems Integration”.',
                picUrl: require('@/assets/images/metting_user1.png')
            },
            {
                title: 'Keynote Speaker of the 2023 Conference',
                name: 'Wang Zhengwei',
                designation: 'Head of the Fluid Machinery and Engineering Research Team of Tsinghua University, Tenured Professor, and Doctoral Supervisor. Member of the Academic Committee of the IAHR Hydraulic Machinery and Systems and Chairman of the Asian branch, Deputy Chairperson of the Turbine Professional Committee of the Chinese Society of Power Engineering, Member of the National Technical Committee for Turbine Standardization, Council Member of the Chinese Renewable Energy Society, and Deputy Chairperson of the Marine Energy Professional Committee of the China Renewable Energy Society.',
                picUrl: require('@/assets/images/metting_user2.png')
            },
            {
                title: 'Keynote Speaker of the 2023 Conference',
                name: 'Liu Xintian',
                designation: 'Deputy Chief Engineer, Deputy Chief Designer/Technical Expert of Harbin Electric Machinery Company Limited, and a Professorate Senior Engineer. The Main research directions are the design of hydroelectric generators, preliminary design, handling of on-site installation problems, handling of commissioning problems, etc. He once presided over and designed the 460MW generator of Shuibuya in Hubei Province. He has been awarded the Second Prize of the Science and Technology Progress Award from the Heilongjiang Provincial Government and the Second Prize of the Science and Technology Progress Award of the China Society for Hydropower Engineering.',
                picUrl: require('@/assets/images/metting_user03.png')
            },
            {
                title: 'Keynote Speaker of the 2023 Conference',
                name: 'Guo Wei',
                designation: 'Dean of the School of Mechanical Engineering at Tianjin Ren\'ai College, Tenured Professor in the School of Mechanical Engineering at Tianjin University, Doctoral Supervisor for Mechanical Engineering and Management Science and Engineering, Doctor, Postdoctoral Fellow in the Postdoctoral Mobile Station of Internal Combustion Engines and Engineering Thermophysics at Tianjin University. He is an expert of the Overall Expert Group of the Manufacturing Informatization Science and Technology Project of the Ministry of Science and Technology and an expert of the Expert Group for Industrial Engineer Qualification Certification of the Chinese Mechanical Engineering Society.',
                picUrl: require('@/assets/images/metting_user04.png')
            },
            {
                title: 'Keynote Speaker of the 2023 Conference',
                name: 'Li Mingtao',
                designation: 'Doctor, Associate Professor in the Department of New Energy Science and Engineering at Xi\'an Jiaotong University, and Doctoral Supervisor. Currently, he is a member of the Professional Committee on Energy Conservation in Public Institutions of the Chemical Industry and Engineering Society of China, a young member of the Professional Committee on Process Modeling and Simulation of the Chinese Chemical Society, and a member of the Shaanxi Provincial Standardization Committee for New Energy and Electric Transportation in the Power Industry. He is also the founder of the open-source organization Ai4Energy (https://github.com/ai4energy).',
                picUrl: require('@/assets/images/metting_user05.png')
            },
            {
                title: 'Keynote Speaker of the 2023 Conference',
                name: 'Sun Jiang',
                designation: 'Chairman of the Conference, Leading Talent of Tianjin Ren\'ai College, Professor, Doctor, Chairman of Tianfa General Factory, and General Manager of Shenzhen Hengfa Hydropower Company. He is among the first batch of talent projects such as the "Outstanding Entrepreneur Training Project" in Tianjin. As a leading talent of Tianjin Ren\'ai College, he has been awarded honorary titles such as the Tianjin May Day Labor Medal and Outstanding Entrepreneur of the Chinese Machinery Industry. He has presided over 12 national and provincial-level scientific research and innovation projects, published 6 papers indexed by EI or higher, and holds nearly 50 utility model and invention patents.',
                picUrl: require('@/assets/images/metting_user06.png')
            },
            {
                title: 'Keynote Speaker of the 2023 Conference',
                name: 'Huo Qiantao',
                designation: 'Deputy General Manager of the Electric Control Branch of NARI Group Co., Ltd., Senior Engineer. He has been engaged in research work on generator excitation, condenser control, active support of new energy, and other aspects for a long time. He has also been involved in the development, design, and on-site testing work of excitation systems for thermal power units. He proposed an accurate method for digital discretization control models, passed the CE certification. He has successively won the First Prize of the China Electric Power Science and Technology Award of the Chinese Society of Electrical Engineering.',
                picUrl: require('@/assets/images/metting_user07.png')
            },
            {
                title: 'Guest Speakers for the Promotion and Implementation of Standards in 2023',
                name: 'Zhou Lin',
                designation: 'Executive Director of RIZNER Co., Ltd. in Australia, Member of the Protection and Excitation Special Committee of China Society for Hydropower Engineering, and Member of the Engineers Australia (MIEAuSt). Zhou Lin has been engaged in research work on power system protection control and on-site monitoring for a long time. He has obtained 7 patented technologies related to arc flash protection. Recent key projects include the applications of the Wudongde and Baihetan giant hydropower stations of the China Three Gorges Corporation.',
                picUrl: require('@/assets/images/metting_user08.png')
            },
            {
                title: 'Guest Speakers for the Promotion and Implementation of Standards in 2023',
                name: 'Luo Zhizhao',
                designation: 'Chairman of Guangdong Rizhao Electric Co., Ltd., and the first inventor of energy-saving copper-clad aluminum tubular busbars. He is professionally engaged in the development, design, research, and application of energy-saving busbars and insulation coordination. He invented the technology of energy-saving copper-clad aluminum tubular busbars and has obtained more than 100 authorized invention patents both at home and abroad. He has won 2 Honors of China Patent Excellence Award and other honors. He has also presided over the formulation of 2 enterprise standards, 2 local standards, and 2 group standards.',
                picUrl: require('@/assets/images/metting_user09.png')
            },
        ]
    },
    newDetailPage: {
        name1: 'Category',
        name2: 'Share',
        name3: 'Scan the QR code turn to the webpage on phone'
    },
    newsPage: {
        name1: 'More',
        name2: 'Review of Past Sessions',
        name3: 'Category',
    },
    contactUsPage: {
        contact1: {
            title: 'Contact Numbers of the Conference Organizing Committee',
            content: ['+86 022-26995595']
        },
        contact2: {
            title: 'Conference Contacts',
            content: ['Jin Yanmei, Tel: +86 15900270919','Jin Huancheng, Tel: +86 13820434300']
        },
    }
}
export default en;